import { useSelector } from 'react-redux';

import { Spinner } from 'components';
import { ArchiveViewType } from 'types';
import { getRequestFormProcessSelector } from 'store/selectors/requestFormProcess';
import { FormsArchiveTable } from '../../components/FormsTable';
import { onHandleViewFormType } from '../../Forms';

interface ArchivePageProps {
  isArchive: boolean;
  archiveView: ArchiveViewType;
  handleViewForm: onHandleViewFormType;
  setSelectedFormProcess?: (formProcessId: number, formStatus?: string) => void;
  refetchForms: Function;
  loadingForms: boolean;
}

export const ArchivePage = ({
  handleViewForm,
  setSelectedFormProcess,
  refetchForms,
  loadingForms,
}: ArchivePageProps) => {
  const { isPending } = useSelector(getRequestFormProcessSelector);
  return (
    <div>
      {isPending || loadingForms ? (
        <Spinner />
      ) : (
        <FormsArchiveTable
          handleViewForm={handleViewForm}
          setSelectedFormProcess={setSelectedFormProcess}
          refetchForms={refetchForms}
        />
      )}
    </div>
  );
};
