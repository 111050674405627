import { Input } from 'components';
import { Select, Tag } from 'components-antd';
import { Fragment, useCallback } from 'react';

import { RequiredAsterik } from '../RequiredAsterik';
import { useScrollableRef } from '../hooks/useScrollableRef';
import { ErrorMessage } from '../ErrorMessages';
import styles from './style.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getFormMetaSelector } from 'store/selectors/requestFormProcess';
import { caseInsensitiveFilter } from 'helpers';

interface TemplateFieldsProps {
  fieldState: { templateName: string; categories: any[] };
  onChange: (data) => void;
  setErrors: (value) => void;
  errors: { templateNameError: string; categoriesError: string };
  saveAsTemplate: boolean;
  className?: string;
  fileNameLabel?: string;
  fileNameRequired?: boolean;
  isNewTemplate?: boolean;
  isBundle?: boolean;
}

export const TemplateFields = ({
  fieldState,
  onChange,
  setErrors,
  errors,
  saveAsTemplate,
  className,
  fileNameLabel,
  fileNameRequired = true,
  isNewTemplate,
  isBundle = false,
}: TemplateFieldsProps) => {
  const { templateName, categories } = fieldState;
  const { tags: formCategories } = useSelector(getFormMetaSelector);

  const { scrollableDivRef } = useScrollableRef();

  const getOptions = useCallback(() => {
    const options: Array<{ label: string; value: number }> = [];

    formCategories?.forEach((el) => {
      options.push({ label: el.Name, value: el.TagId });
    });

    return options;
  }, []);

  return (
    <div className={classNames(styles.templateContent, className)}>
      <div className={styles.templateFieldsWrapper} ref={!isNewTemplate ? scrollableDivRef : null}>
        {saveAsTemplate ? (
          <Fragment>
            <div className={styles.inputBlock}>
              <div className={styles.label}>
                {isBundle ? 'Bundle Name' : 'Template Name'} <RequiredAsterik />
              </div>
              <Input
                className={styles.input}
                inputHolderClassName={styles.inputHolder}
                variant={Input.LIGHT_ROUND}
                placeholder="Enter name"
                value={templateName}
                maxLength={255}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    templateNameError: '',
                  });
                  onChange({ templateName: e.target.value });
                }}
                name="TemplateName"
                id="TemplateName"
                testid="template_name"
              />
              <ErrorMessage id="TemplateNameError" message={errors.templateNameError} />
            </div>

            {!isBundle ? (
              <div className={classNames(styles.inputBlock, styles.categorySelect)}>
                <div className={styles.label}>
                  Category <RequiredAsterik />
                </div>
                <Select
                  mode="tags"
                  className={`${styles.inputHolder} mosaikSelect`}
                  options={getOptions()}
                  maxTagCount={'responsive'}
                  value={categories}
                  onSelect={(value) => {
                    const existingOption = getOptions().find(
                      (option) => option.value === value || option.label === value,
                    );

                    if (existingOption) {
                      onChange({ categories: [...categories, existingOption.value] });
                    }
                  }}
                  onChange={(value) => {
                    let hasNewCategory = false;

                    if (value.length) {
                      const allCategories = value.join(', ').toLowerCase();
                      hasNewCategory =
                        allCategories.includes('new category') ||
                        allCategories.includes('newcategory');
                    }

                    if (!hasNewCategory) {
                      setErrors({
                        ...errors,
                        categoriesError: '',
                      });

                      onChange({ categories: value });
                    }
                  }}
                  placement="bottomLeft"
                  placeholder={'Select category'}
                  getPopupContainer={(node) => node}
                  popupClassName={`${styles.inputHolderDropdown} mosaikSelectDropdown`}
                />
                <ErrorMessage id="CategoriesError" message={errors.categoriesError} />
              </div>
            ) : (
              <></>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <div className={styles.inputBlock}>
              <div className={styles.label}>
                {fileNameLabel || 'File Name'} {fileNameRequired ? <RequiredAsterik /> : <></>}
              </div>
              <Input
                className={styles.input}
                inputHolderClassName={styles.inputHolder}
                variant={Input.LIGHT_ROUND}
                placeholder="Enter name"
                value={templateName}
                maxLength={150}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    templateNameError: '',
                  });
                  onChange({ templateName: e.target.value });
                }}
                name="FileName"
                id="FileName"
                testid="template_name"
              />
              <ErrorMessage id="FileNameError" message={errors.templateNameError} />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};
