import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Row, Col } from 'components-antd/Grid';
import { appSetInviteClientValuesAction } from 'store/actions/app';
import { getInvitedClientValues } from 'store/selectors/app';

import { Input, FormTitle, Answers, Wrapper } from 'components';
import Footer from '../../../Footer';
import { InviteHeader } from '../../../Headers';
import { schema } from './validation';
import { ClientCategory } from 'types';

import styles from './styles.module.scss';
import { inviteClientSteps } from 'types/inviteClient';
import { useEffect, useState } from 'react';
import { getListOfAgentMlsServices } from 'api/agent/mls';
import { showErrorMessage } from 'helpers';

const options = [
  { id: ClientCategory.Buyer, label: 'Buying', value: ClientCategory.Buyer },
  { id: ClientCategory.Seller, label: 'Selling', value: ClientCategory.Seller },
  { id: ClientCategory.Retention, label: 'Retention', value: ClientCategory.Retention },
];

const ClientDetails = ({ onNext, stageIndex }) => {
  const dispatch = useDispatch();
  const initValues = useSelector(getInvitedClientValues);
  const [isLoading, setIsLoading] = useState(true);
  const [hasActiveMls, setHasActiveMls] = useState(false);

  const formik = useFormik({
    initialValues: { firstName: '', lastName: '', email: '', clientType: [], ...initValues },
    validateOnMount: true,
    validationSchema: schema,
    onSubmit: (values) => {
      values = { ...values, Email: values.email.toLowerCase() };
      dispatch(appSetInviteClientValuesAction(values));
      const bothRoles = [ClientCategory.Buyer, ClientCategory.Seller].every((value) =>
        values.clientType.includes(value),
      );
      const buyerRole = values.clientType.includes(ClientCategory.Buyer);
      const retentionRole = values.clientType.includes(ClientCategory.Retention);

      if (bothRoles) {
        onNext(inviteClientSteps.ClientTransactionInstance);
      } else if (buyerRole) {
        onNext(inviteClientSteps.ClientSearchInstance);
      } else if (retentionRole) {
        onNext(inviteClientSteps.ClientRetentionMode);
      } else {
        onNext(inviteClientSteps.ClientTransactionInstance);
      }
    },
  });
  const {
    values,
    touched,
    errors,
    isValid,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
  } = formik;

  const handleRadioOptionChange = (value) => {
    setFieldValue('clientType', value);
  };

  const expand = values.firstName && values.lastName && values.email && !errors.email;

  const fetchListing = async () => {
    try {
      const response = await getListOfAgentMlsServices();
      if (response.data?.success) {
        setHasActiveMls(response.data.result.some((item) => item.Status === 'approved'));
      }
    } catch (err) {
      handleError();
    }
    setIsLoading(false);
  };

  const handleError = () => {
    showErrorMessage("Cannot load list of available agent MLS'. Try to reload the page");
  };

  useEffect(() => {
    fetchListing();
  }, []);

  const mlsError = values.clientType.includes(ClientCategory.Buyer) && !hasActiveMls;

  return (
    <Wrapper isPending={isLoading}>
      <InviteHeader stageIndex={stageIndex} isValid={isValid} />
      <form testid="invite_details" className={styles.clientInfoForm} onSubmit={handleSubmit}>
        <div className={classNames(styles.formContainer, { [styles.expand]: expand })}>
          <FormTitle className={styles.title}>Contact Information</FormTitle>
          <div className={styles.contactInformation}>
            <Row gutter={[24, 24]}>
              <Col xs={24} md={12}>
                <Input
                  name="firstName"
                  variant={Input.LIGHT_ROUND}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  label="First Name"
                  error={touched.firstName ? (errors.firstName as string) : ''}
                  testid="first_name"
                  maxLength={30}
                />
              </Col>
              <Col xs={24} md={12}>
                <Input
                  name="lastName"
                  variant={Input.LIGHT_ROUND}
                  onChange={handleChange}
                  value={values.lastName}
                  onBlur={handleBlur}
                  label="Last Name"
                  error={touched.lastName ? (errors.lastName as string) : ''}
                  testid="last_name"
                  maxLength={30}
                />
              </Col>
              <Col xs={24} md={24}>
                <Input
                  name="email"
                  variant={Input.LIGHT_ROUND}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  label="Email"
                  error={touched.email ? (errors.email as string) : ''}
                  testid="email"
                  maxLength={50}
                />
              </Col>
            </Row>
          </div>
          <div className={classNames(styles.clientType, { [styles.expand]: expand })}>
            <h4 className={styles.header}>The focus for {values.firstName} is:</h4>

            <Answers
              multiple
              cases={options}
              variant={Answers.CHECKBOX}
              direction="row"
              checkboxOptionClassname={styles.checkboxOption}
              onChange={handleRadioOptionChange}
              value={values.clientType}
              classNameHolder={styles.holder}
            />
          </div>
          {mlsError && <p className={styles.error}>You need atleast one active MLS to proceed</p>}
        </div>
        <Footer
          testid="invite_details_footer"
          buttonTitle="Continue"
          isValid={isValid && !mlsError}
        />
      </form>
    </Wrapper>
  );
};

ClientDetails.propTypes = {
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
};

ClientDetails.defaultProps = {
  onNext: () => {},
  stageIndex: 0,
};

export default ClientDetails;
