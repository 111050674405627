import { useEffect, useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';
import { CompareFn } from 'antd/lib/table/interface';

import { renderSortableTitle, sorterWithCompletedOnTop } from './sortingHelpers';
import { Checkbox } from 'components-antd';
import { Button, Popover, Select, OptionType, Tooltip } from 'components-antd';
import { FieldContainer } from 'pages/FormProcess/components/PreForm/ClientOrTransaction/components/FieldContainer';
import {
  getClientNamesFromClients,
  getClientOptions,
  getTransactionOptions,
} from 'pages/FormProcess/components/PreForm/ClientOrTransaction';

import {
  getAllFormProcessesSelector,
  getFormMetaSelector,
  getFormsFiltersSelector,
  getRequestFormProcessSelector,
} from 'store/selectors/requestFormProcess';
import { onHandleViewFormType, renderTooltip } from '../../Forms';
import { Icons } from '../../Icons';
import { ICON_VARIANT_TYPE, Icons as FormIcons } from 'pages/FormProcess/Icons/index';
import { FormsTableStatus } from 'app-constants';
import { FormProcessType } from 'types';
import { getTitleByType } from 'utils/aggregatedPageTypeHelper';
import { getAggregatePageTypeSelector } from 'store/selectors/workshop';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { Avatar, Wrapper } from 'components';
import { CollapsibleTable, WorkshopTableWrapper } from 'pages/Workshop/common';
import { NamesContainer } from 'pages/Workshop/Transactions/components/NamesContainer';
import { getUserRolesMapSelector, getUserSelector } from 'store/selectors/user';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { linkToFormProcessEffect } from 'store/effects/formProcess';

import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { caseInsensitiveFilter, showSuccessMessage } from 'helpers';
import { getProjectFilterSelector } from 'store/selectors/transactions';
import { isExternalMember } from './helper';

export const renderStatus = (status: string) => {
  function getStatusClassName() {
    switch (status) {
      case FormsTableStatus.WaitingOnYou:
        return styles.waitinOnYou;
      case FormsTableStatus.WaitingOnOthers:
        return styles.waitinOnOthers;
      case FormsTableStatus.Finalizing:
        return styles.finalizing;
      case FormsTableStatus.Complete:
        return styles.complete;
      case FormsTableStatus.Draft:
        return styles.draft;
      default:
        return '';
    }
  }
  return (
    <div className={classNames(styles.formsStatus, getStatusClassName())}>
      <span className={styles.statusText}>{status}</span>
    </div>
  );
};

export const renderLastActivity = (form: FormProcessType, justSignedFormProcessPublicId) => {
  const { status, date, waitingOnYou, isFinalizing, isEdit, isSign, IsSequential, isOwner } = form;

  const isJustSignedForm =
    justSignedFormProcessPublicId &&
    justSignedFormProcessPublicId === form.formProcessPublicId &&
    (!IsSequential || !isOwner);

  const renderWaitingOn = () => {
    return (
      <div className={styles.lastActivityContainer}>
        {renderTooltip(
          form,
          <div className={styles.lastActivity}>
            <div
              className={styles.exclamationContainer}
              style={{
                backgroundColor: !isJustSignedForm && waitingOnYou ? '#FEEDEF' : '#F4F5F6',
              }}
            >
              <Icons
                variant="exclamation"
                size={20}
                stroke={!isJustSignedForm && waitingOnYou ? '#FF576D' : '#747475'}
              />
            </div>
            <div>
              {isEdit && !isJustSignedForm && (
                <div className={classnames(waitingOnYou && styles.textRed)}>
                  <span>Form Incomplete: </span>
                  <span>{renderActivityDate(date)}</span>
                </div>
              )}
              {isSign && !isJustSignedForm && (
                <span className={classnames(styles.line2, waitingOnYou && styles.textRed)}>
                  <span className={styles.pendingText}>
                    {isFinalizing
                      ? 'Pending:'
                      : waitingOnYou
                      ? 'Signature Needed:'
                      : 'Pending Signatures:'}
                  </span>
                  <span> {renderActivityDate(date)}</span>
                </span>
              )}
              {isJustSignedForm && (
                <span className={styles.line2}>
                  <span className={styles.pendingText}>Pending:</span>
                  <span> {renderActivityDate(date)}</span>
                </span>
              )}
            </div>
          </div>,
        )}
      </div>
    );
  };

  const renderComplete = () => {
    return (
      <div className={styles.lastActivity}>
        <span>Completed:</span>
        <div>
          <span> {' ' + renderActivityDate(date)}</span>
        </div>
      </div>
    );
  };

  const renderDraft = () => {
    return (
      <div className={styles.lastActivity}>
        <span>Last Edited:</span>
        <span> {' ' + renderActivityDate(date)}</span>
      </div>
    );
  };

  const renderActivityDate = (date) => {
    const now = moment(new Date());
    const formDate = moment(date);
    var duration = moment.duration(now.diff(formDate));
    var hours = duration.asHours();
    return hours > 48 ? moment(date).format('MM/DD/YYYY') : moment(date).fromNow();
  };

  const renderTheLastActivity = () => {
    switch (status) {
      case FormsTableStatus.WaitingOnYou:
        return renderWaitingOn();
      case FormsTableStatus.WaitingOnOthers:
        return renderWaitingOn();
      case FormsTableStatus.Finalizing:
        return renderWaitingOn();
      case FormsTableStatus.Complete:
        return renderComplete();
      case FormsTableStatus.Draft:
        return renderDraft();
      default:
        return renderComplete();
    }
  };

  return (
    <div
      className={classnames(styles.status, {
        [styles.statusWarning]: waitingOnYou,
      })}
    >
      {renderTheLastActivity()}
    </div>
  );
};

export const ClientColumn = ({ row, setEditId, editId, refetchForms }) => {
  const clientName = row?.client;
  const isOwner = row?.isOwner;
  const getAllNames = clientName.split(',');
  const [clientIds, setClientIds] = useState<any>(null);

  const { clients } = useSelector(getFormMetaSelector);

  const getPlaceHolder = (fullName) => {
    const nameArray = fullName.split(' ');
    const firstName = nameArray[0];
    const lastName = nameArray[nameArray.length - 1];
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  };

  const content = (
    <FieldContainer label="Client" inputClass={styles.multipleSelectInput}>
      <Select
        mode="multiple"
        value={clientIds?.clientIds}
        onChange={(option) => {
          setClientIds({
            clientIds: option,
            clientName: getClientNamesFromClients(option, clients),
          });
        }}
        showSearch
        size="large"
        className={styles.listBoxInput}
        options={getClientOptions(clients)}
        filterOption={(inputValue, option) =>
          caseInsensitiveFilter(inputValue, option?.props?.label)
        }
        maxTagCount={'responsive'}
        maxTagPlaceholder={(omittedValues) => (
          <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
            <span>{`+${omittedValues.length}`}</span>
          </Tooltip>
        )}
        placeholder="Select Client(s)"
      />
    </FieldContainer>
  );

  return (
    <div className={styles.clientWrapper}>
      {clientName ? (
        <>
          <Avatar
            placeholder={getPlaceHolder(getAllNames[0])}
            avatarClassName={styles.avatarCustom}
          />
          <div>
            <NamesContainer names={getAllNames} maxDisplayCount={1} />
          </div>
        </>
      ) : isOwner ? (
        <LinkPopover
          editId={editId}
          popupKey="client"
          formProcessId={row?.formProcessId}
          setEditId={setEditId}
          content={content}
          data={clientIds}
          setData={(value) => setClientIds(value)}
          refetchForms={refetchForms}
        />
      ) : (
        '-'
      )}
    </div>
  );
};

export const TransactionColumn = ({ row, editId, setEditId, refetchForms }) => {
  const address = row?.address;
  const isOwner = row?.isOwner;
  const name = row?.projectName;
  const [transactionId, setTransactionId] = useState(null);
  const { transactions } = useSelector(getFormMetaSelector);

  const content = (
    <FieldContainer label="Transaction" icon={ICON_VARIANT_TYPE.DoubleArrow}>
      <Select
        value={transactionId}
        labelInValue
        onChange={(option) => setTransactionId(option.value)}
        showSearch
        size="large"
        className={classNames(styles.listBoxInput, styles.transactionListInput)}
        options={getTransactionOptions(transactions)}
        filterOption={(inputValue, option) =>
          caseInsensitiveFilter(inputValue, option?.props?.label)
        }
        placeholder="Select Transaction"
        suffixIcon={<FormIcons variant={ICON_VARIANT_TYPE.Arrow} stroke="#747475" />}
      />
    </FieldContainer>
  );

  if (address) {
    const addressLines = address.split(', ');

    return (
      <div className={styles.addressContainer}>
        <span className={styles.line1}>{addressLines[0]}</span>
        <span className={styles.line2}> {addressLines.slice(1).join(', ')}</span>
      </div>
    );
  } else {
    return (
      <div className={styles.addressContainer}>
        {name ? (
          <span>{name}</span>
        ) : isOwner ? (
          <LinkPopover
            editId={editId}
            popupKey="transaction"
            formProcessId={row?.formProcessId}
            setEditId={setEditId}
            content={content}
            data={transactionId}
            setData={(value) => setTransactionId(value)}
            refetchForms={refetchForms}
          />
        ) : (
          '-'
        )}
      </div>
    );
  }
};

const LinkPopover = ({
  editId,
  popupKey,
  formProcessId,
  setEditId,
  data,
  setData,
  content,
  refetchForms,
}) => {
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);

  const handleLinkClick = (e) => {
    e.stopPropagation();
    if (editId === null) {
      setData(null);
      setEditId(`${popupKey}-${formProcessId}`);
      setOpenPopup(true);
    }
  };

  const onCancel = (e) => {
    e.stopPropagation();
    setEditId(null);
    setOpenPopup(false);
  };

  const onUpdate = async () => {
    setUpdateLoading(true);
    const payload =
      popupKey === 'client' ? { clientIds: data?.clientIds } : { transactionId: data };

    dispatch(
      linkToFormProcessEffect(
        {
          formProcessIds: [formProcessId],
          ...payload,
        },
        (err) => {
          if (!err) {
            refetchForms();
            setOpenPopup(false);
            setEditId(null);
            showSuccessMessage('Form linked successfully.');
          }
          setUpdateLoading(false);
        },
      ),
    );
  };

  useEffect(() => {
    setOpenPopup(`${popupKey}-${formProcessId}` === editId);
  }, [editId]);

  return (
    <Popover
      content={
        <div className={styles.transactionPopup} onClick={(e) => e.stopPropagation()}>
          {content}
          <div className={styles.buttonsContainer}>
            <Button
              variant="secondary"
              size="large"
              onClick={onUpdate}
              loading={updateLoading}
              disabled={updateLoading}
            >
              Done
            </Button>
            <Button variant="hollow-bordered" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      }
      trigger="click"
      showArrow={false}
      overlayClassName={styles.transactionPopupOverlay}
      open={openPopup}
      onOpenChange={(value) => {
        setOpenPopup(value);
        if (!value) setEditId(null);
      }}
    >
      <div className={styles.widthFitContent}>
        <span className={classNames(styles.placeholder, { [styles.displayNone]: openPopup })}>
          -
        </span>
        <Button
          variant="hollow-bordered"
          className={classNames(styles.linkButton, { [styles.visibleFlex]: openPopup })}
          onClick={handleLinkClick}
        >
          + Link
        </Button>
      </div>
    </Popover>
  );
};

interface FormTableProps {
  setSelectedFormProcess?: (formProcessId: number, formStatus?: string) => void;
  handleViewForm: onHandleViewFormType;
  justSignedFormProcessPublicId?: string;
  handleSelectForm: Function;
  selectedFormIds: number[];
  showSelectAll: boolean;
  showSelection: boolean;
  refetchForms: Function;
  loadingForms: boolean;
}

export const FormsTable = ({
  setSelectedFormProcess,
  handleViewForm,
  justSignedFormProcessPublicId,
  handleSelectForm,
  selectedFormIds,
  showSelectAll,
  showSelection,
  refetchForms,
  loadingForms = false,
}: FormTableProps) => {
  const {
    waitingOnYou,
    waitingOnOthers,
    draftForms = [],
    finalizingForms = [],
    recentlyCompletedForms = [],
  } = useSelector(getAllFormProcessesSelector);
  const { isPending } = useSelector(getRequestFormProcessSelector);

  const aggregatedPageType = useSelector(getAggregatePageTypeSelector);
  const { search } = useSelector(getFormsFiltersSelector);
  const { isClient, isThirdParty } = useSelector(getUserRolesMapSelector);
  const teamRole = useSelector(getAgentTeamRoleSelector);
  const [editId, setEditId] = useState(null);

  const formCategoryFilter = useSelector(getProjectFilterSelector);
  let dataSource = [
    ...waitingOnYou.map((data) => ({
      ...data,
      key: data.formDocumentId,
      status: FormsTableStatus.WaitingOnYou,
    })),

    ...waitingOnOthers.map((data) => ({
      ...data,
      key: data.formDocumentId,
      status: FormsTableStatus.WaitingOnOthers,
    })),

    ...finalizingForms.map((data) => ({
      ...data,
      key: data.formDocumentId,
      status: FormsTableStatus.Finalizing,
    })),

    ...recentlyCompletedForms.map((data) => ({
      ...data,
      key: data.formDocumentId,
      status: FormsTableStatus.Complete,
    })),

    ...draftForms.map((data) => ({
      ...data,
      key: data.formDocumentId,
      status: FormsTableStatus.Draft,
    })),
  ];

  if (formCategoryFilter !== 'All Forms') {
    switch (formCategoryFilter) {
      case 'My Forms':
        dataSource = dataSource.filter((data) => data.isOwner);
        break;
      case 'Team Forms':
        dataSource = dataSource.filter(
          (data) => !data.isOwner && (data.isSignatory || data.isEditor),
        );
        break;
      case 'External Forms':
        dataSource = dataSource.filter(
          (data) => !data.isOwner && !data.isSignatory && !data.isEditor,
        );
        break;
    }
  }

  const formsData: any = {
    waitingOnYouData: [],
    waitingOnOthersData: [],
    draftData: [],
    completedData: [],
  };

  for (const data of dataSource) {
    let keepItem = true;

    if (search) {
      keepItem = data.formName.toLowerCase().includes(search.toLowerCase());
    }

    if (!keepItem) continue;

    let addAtStart = false;

    if (
      justSignedFormProcessPublicId &&
      data.formProcessPublicId === justSignedFormProcessPublicId
    ) {
      if (data.status === FormsTableStatus.WaitingOnYou && (!data.IsSequential || !data.isOwner)) {
        // Signatures on backend work asynchronously, and most likely when requesting forms right after signing
        // it will return "Waiting on you" status for just completed form. Though we don't want to confuse users
        // and will show "Finalizing instead"

        const otherSignatories = ((data?.signatories as any) ?? []).filter((s) => !s.isYou);
        if (otherSignatories.some((s) => s.status !== 'Signed')) {
          data.status = FormsTableStatus.WaitingOnOthers;
        } else {
          data.status = FormsTableStatus.Finalizing;
        }
      }

      addAtStart = true;
    }

    switch (data.status) {
      case FormsTableStatus.WaitingOnYou:
        if (addAtStart) {
          formsData.waitingOnYouData.unshift(data);
        } else {
          formsData.waitingOnYouData.push(data);
        }
        break;

      case FormsTableStatus.WaitingOnOthers:
        if (addAtStart) {
          formsData.waitingOnOthersData.unshift(data);
        } else {
          formsData.waitingOnOthersData.push(data);
        }
        break;

      case FormsTableStatus.Draft:
        if (addAtStart) {
          formsData.draftData.unshift(data);
        } else {
          formsData.draftData.push(data);
        }
        break;

      default:
        if (addAtStart) {
          formsData.completedData.unshift(data);
        } else {
          formsData.completedData.push(data);
        }
        break;
    }
  }

  const openFormDetailModal = (form) => {
    if (setSelectedFormProcess && (form.isOwner || isExternalMember(form)))
      setSelectedFormProcess(form.formProcessId, form.status || '');
    else if (form.url && handleViewForm) {
      handleViewForm(form.url, form.type);
    }
  };

  const StatusColumn = ({ row: { status } }) => {
    return renderStatus(status);
  };

  const LastActivityColumn = ({ row }) => {
    return renderLastActivity(row, justSignedFormProcessPublicId);
  };

  const FormColumn = ({ row }) => {
    const formName = row?.formName;

    return (
      <div className={classnames(styles.row, styles.name)}>
        <div className={styles.formAvatarIcon}>
          <Icons variant="file" />
        </div>
        <div className={styles.flexCol}>
          <span className={styles.formName}>{formName}</span>
        </div>
      </div>
    );
  };

  const OwnerColumn = ({ row }) => {
    const ownerName = `${row?.creator?.FirstName} ${row?.creator?.LastName}`;
    return (
      <div className={styles.ownerWrapper}>
        {ownerName && row?.creator ? (
          <>
            <Avatar placeholder={row?.creator?.AvatarUrl} avatarClassName={styles.avatarCustom} />
            <div>
              <NamesContainer names={[ownerName]} maxDisplayCount={1} />
            </div>
          </>
        ) : (
          '-'
        )}
      </div>
    );
  };

  const columns = [
    {
      title: renderSortableTitle('formName', 'Form'),
      dataIndex: 'formName',
      key: 'formName',
      width: 300,
      render: (text, row) => <FormColumn row={row} />,
      sorter: sorterWithCompletedOnTop('formName') as CompareFn<FormProcessType>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (text, row) => <StatusColumn row={row} />,
    },
    {
      title: renderSortableTitle('address', getTitleByType(aggregatedPageType)),
      dataIndex: 'address',
      key: 'address',
      width: 250,
      render: (text, row) => (
        <TransactionColumn
          row={row}
          setEditId={(value) => setEditId(value)}
          editId={editId}
          refetchForms={refetchForms}
        />
      ),
      sorter: sorterWithCompletedOnTop('address') as CompareFn<FormProcessType>,
    },
    ...(!isClient
      ? [
          {
            title: renderSortableTitle('client', 'Client'),
            dataIndex: 'client',
            key: 'client',
            width: 250,
            render: (text, row) => (
              <ClientColumn
                row={row}
                setEditId={(value) => setEditId(value)}
                editId={editId}
                refetchForms={refetchForms}
              />
            ),
            sorter: sorterWithCompletedOnTop('client') as CompareFn<FormProcessType>,
          },
        ]
      : []),
    ...(teamRole === TEAM_OWNER || teamRole === TEAM_ADMIN
      ? [
          {
            title: 'Owner',
            dataIndex: 'creator',
            key: 'creator',
            width: 250,
            render: (text, row) => <OwnerColumn row={row} />,
          },
        ]
      : []),
    {
      title: 'Last Activity',
      dataIndex: 'status',
      key: 'lastActivity',
      width: 300,
      render: (text, row) => <LastActivityColumn row={row} />,
    },
    ...(showSelectAll && showSelection
      ? [
          {
            title: '',
            key: 'checkbox',
            width: 1,
            render: (_, { formProcessId, isOwner, formName }) => {
              return isOwner ? (
                <div className={styles.multiSelectionCheckbox}>
                  <Checkbox
                    className={styles.checkbox}
                    checked={selectedFormIds?.includes(formProcessId)}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleSelectForm(formProcessId, formName, e.target.checked);
                    }}
                  />
                </div>
              ) : (
                ''
              );
            },
          },
        ]
      : []),
  ];

  const tableProps = (status) => ({
    columns,
    onRow: (record) => {
      return {
        onClick: () => {
          if (editId === null) openFormDetailModal({ ...record, status });
        },
      };
    },
    tableClassName: styles.tableView,
    pagination: false,
  });

  let tablesData = [
    { title: 'Waiting on You', data: formsData.waitingOnYouData },
    { title: 'Waiting on Others', data: formsData.waitingOnOthersData },
    { title: 'Recently Completed', data: formsData.completedData },
  ];

  if (!isClient && !isThirdParty)
    tablesData = [...tablesData, { title: 'Drafts', data: formsData.draftData }];

  return (
    <Wrapper isPending={isPending || loadingForms}>
      <WorkshopTableWrapper
        data={[
          ...formsData.waitingOnYouData,
          ...formsData.waitingOnOthersData,
          ...formsData.completedData,
          ...formsData.draftData,
        ]}
        emptyText="No Forms."
      >
        {tablesData?.map(({ title, data }) =>
          data.length ? (
            <CollapsibleTable
              title={title}
              dataSource={data}
              {...tableProps(data.length > 0 ? data[0].status : '')}
              rowClassName={(record) => {
                const clientEditId = 'client-' + record?.formProcessId;
                const transactionEditId = 'transaction-' + record?.formProcessId;
                if (editId !== null && clientEditId !== editId && transactionEditId !== editId)
                  return styles.disabledRow;
              }}
            />
          ) : null,
        )}
      </WorkshopTableWrapper>
    </Wrapper>
  );
};
