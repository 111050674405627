import { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import moment from 'moment';
import { get, orderBy } from 'lodash-es';

import { Question } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { Continue } from '../../Continue';
import { Modal } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { Button as CustomButton, Wrapper } from 'components';
import { ArrowLeftFull } from 'components/Icons';
import Templates from '../Timeline/Templates';
import TemplatePreview from '../Timeline/TemplatePreview';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import {
  timelineDateControls,
  transactionPreFormQuestionsIds,
} from 'settings/constants/transaction';
import {
  setTransactionPreFormQuestionsEffect,
  resetTransactionCreateTemplateEffect,
} from 'store/effects/transactions';
import { getTransactionTemplateEffect } from 'store/effects/template/transaction';
import { getUserDataSelector } from 'store/selectors/user';
import { getAssignees, processOption } from 'pages/Workshop/Transactions/TransactionCreate/helpers';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';

import useIsProjectRoute from 'hooks/use-is-project-route';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { getAllTransactionParticipantsSelector } from 'store/selectors/transaction';

const TransactionTemplate = ({
  currentQuestionId,
  onNext,
  setStage,
  showSkipButton,
  heading = 'Do you want to apply a transaction template?',
}) => {
  const history = useHistory();
  const isTransactionUnderContractRoute = () =>
    history.location?.pathname.includes('undercontract');

  const getInvitedParticipants = () => {
    // Filtering out clients/agents/coordinators/participants that are invited.
    const { clients, agents, coordinators, participants } = preForm || {};
    return [
      ...(clients || []),
      ...(agents || []),
      ...(coordinators || []),
      ...(participants || []),
    ].filter((participant) => participant.invite);
  };

  const formatCreatorUser = (user) => ({
    id: user.Id,
    value: user.Id,
    firstName: user.FirstName,
    lastName: user.LastName,
    email: user.Email,
    avatarUrl: user.AvatarUrl,
    Phones: user.Phones,
    transactionAccess: true,
    roleId: preForm?.creatorRole?.roleId,
    tagRole: preForm?.creatorRole?.name,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [templateToPreviewId, setTemplateToPreviewId] = useState(null);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [previewData, setPreviewData] = useState();
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const apply = preForm?.[transactionPreFormQuestionsIds.transactionTemplate];
  const creatorUser = useSelector(getUserDataSelector);

  // Created transaction participants
  const transactionParticipants = useSelector(getAllTransactionParticipantsSelector);
  // To be created transaction invited participants
  const preFormParticipants = [...getInvitedParticipants(), formatCreatorUser(creatorUser)];
  const participantOptions = isTransactionUnderContractRoute()
    ? transactionParticipants
    : preFormParticipants;

  const dispatch = useDispatch();
  const isProject = useIsProjectRoute();
  const listRef = useRef();

  const [itemRef, setItemRef] = useState();
  const dateControlsLabels = {
    CD: isProject ? 'Kickoff Date' : 'Contract Date',
    CL: isProject ? 'Deadline' : 'Closing Date',
    LE: 'Listing Expiration Date',
    TL: 'Target Live Date',
  };
  const dateControls = preForm?.[transactionPreFormQuestionsIds.dateControls];
  const [dateControlsMilestones, setDateControlsMilestones] = useState([]);

  useEffect(() => {
    if (!templateToPreviewId && listRef.current && itemRef) {
      listRef.current.scrollTo({
        top: itemRef.scrollTop,
        behavior: 'smooth',
      });

      setItemRef(null);
    }
  }, [templateToPreviewId]);

  useEffect(() => {
    if (dateControls) {
      // Pushing date control milestones for viewing only
      const dateControlitems = Object.keys(dateControls)
        .filter((key) => key !== 'T' && key !== 'O' && dateControls[key] !== undefined)
        .map((key) => ({
          Title: dateControlsLabels[key],
          DueDate: dateControls[key],
          isDateControlMilestone: true,
        }));
      setDateControlsMilestones(dateControlitems);
    }
  }, [dateControls]);

  const getModalTitle = () => {
    if (templateToPreviewId) {
      return (
        <div className={styles.modalTitleLeft}>
          <ArrowLeftFull
            className={styles.backBtn}
            onClick={() => {
              setTemplateToPreviewId(null);
            }}
          />
          <div className={styles.content}>
            <h4 testid="template_title" className={styles.title}>
              {previewData?.title}
            </h4>
            <p testid="template_description" className={styles.desc}>
              {previewData?.description}
            </p>
          </div>
        </div>
      );
    }
    return isProject ? 'Project Template' : 'Transaction Template';
  };

  const getPreviewModaldataHandler = (data) => {
    setPreviewData(data);
  };

  const onPreviewTemplate = (id, ref) => {
    if (ref.current) {
      const { offsetTop, scrollTop, scrollHeight } = ref.current;
      setItemRef({ offsetTop, scrollTop, scrollHeight });
    }
    setTemplateToPreviewId(id);
  };

  const updateValue = useCallback(
    (val, questionId = transactionPreFormQuestionsIds.transactionTemplate) => {
      dispatch(
        setTransactionPreFormQuestionsEffect({
          [questionId]: val,
        }),
      );
    },
    [dispatch],
  );

  const formattedTask = (template) => {
    return {
      AssigneeList: getAssignees(template, 'AssigneeRoles', participantOptions),
      CcList: getAssignees(template, 'CcRoles', participantOptions),
      AssigneeRoles: get(template, 'AssigneeRoles'),
      CcRoles: get(template, 'CcRoles'),
    };
  };

  const formattedFormAsTask = (template) => {
    const dueDate = get(template, 'DueDate');
    const initial = get(template, 'Initial');
    const operator = get(template, 'Operator');
    const dateControl = getDateControlFromInitial(initial) ?? new Date();
    return {
      formTemplateId: template.Id ? String(template.Id) : undefined,
      Title: `Complete form: ${get(template, 'CurrentFormVersion.Name') || ''}`,
      DueDate:
        operator === '-'
          ? moment(dateControl).subtract(dueDate, 'days')
          : moment(dateControl).add(dueDate, 'days'),
      Category: 'Forms',
      Description: '',
      Links: [],
      Documents: [],
      AssigneeList: [processOption(creatorUser)],
      CcList: [],
      IsFormTemplate: true,
      FormId: get(template, 'FormId'),
      IsRequired: get(template, 'IsRequired'),
    };
  };

  const getDateControlFromInitial = (initial) => {
    switch (initial) {
      case timelineDateControls.A: {
        return new Date();
      }
      case timelineDateControls.CD: {
        return preForm[transactionPreFormQuestionsIds.effectiveDate];
      }
      case timelineDateControls.KO: {
        return preForm[transactionPreFormQuestionsIds.effectiveDate];
      }
      case timelineDateControls.CL || timelineDateControls.DL: {
        return preForm[transactionPreFormQuestionsIds.closingDate];
      }
      case timelineDateControls.DL: {
        return preForm[transactionPreFormQuestionsIds.closingDate];
      }
      case timelineDateControls.LE: {
        return preForm[transactionPreFormQuestionsIds.listingExpireDate];
      }
      case timelineDateControls.TL: {
        return preForm[transactionPreFormQuestionsIds.liveDate] || new Date();
      }
      default:
        return null;
    }
  };

  const onSelectTemplate = useCallback(
    (templateId) => {
      setIsTemplateLoading(true);
      setModalOpen(false);
      dispatch(
        getTransactionTemplateEffect({ id: templateId }, {}, (err, res) => {
          if (!err) {
            const template = res?.data?.result;
            const propertyType =
              template?.PropertyType?.length === 1 ? template.PropertyType[0] : null;
            const transactionCategoryId = template?.TransactionCategoryId;
            const milestones = template?.TimelineTemplate?.Milestones || [];
            const taskTemplates = template?.TaskTemplates || [];
            const taskForms = template?.TransactionFormTemplate || [];

            const body = {
              templateId: templateId,
              [transactionPreFormQuestionsIds.tasks]: [],
            };
            if (propertyType) {
              body[transactionPreFormQuestionsIds.propertyType] = propertyType;
            }
            if (transactionCategoryId) {
              body[transactionPreFormQuestionsIds.projectCategory] = transactionCategoryId;
            }

            const formattedMilestones = milestones.map(
              (
                { Title, Offset, Initial, Operator, ControlOperatorOffset, IsPrivate, PublicId },
                index,
              ) => ({
                Title,
                DueDate: getDateControlFromInitial(Initial)
                  ? Operator === '-'
                    ? moment(getDateControlFromInitial(Initial)).subtract(Offset, 'days').toDate()
                    : moment(getDateControlFromInitial(Initial)).add(Offset, 'days').toDate()
                  : null,
                ControlOperatorOffset,
                IsPrivate,
                PublicId,
                milestoneTemplateId: `${PublicId}`,
              }),
            );
            const sortedMilestones = orderBy(
              [...formattedMilestones, ...dateControlsMilestones],
              'DueDate',
              ['asc'],
            );

            body[transactionPreFormQuestionsIds.timeline] = sortedMilestones;

            if (taskTemplates.length) {
              body[transactionPreFormQuestionsIds.tasks] = orderBy(
                taskTemplates.map((task) => formattedTask(task)),
                'templateTaskId',
                ['asc'],
              );
            }

            if (taskForms.length) {
              taskForms.forEach((form) => {
                body[transactionPreFormQuestionsIds.tasks].push(formattedFormAsTask(form));
              });
            }

            dispatch(setTransactionPreFormQuestionsEffect(body));
            onNextHandler();
          }
          setIsTemplateLoading(false);
        }),
      );
    },
    [updateValue, dispatch, currentQuestionId],
  );

  const onNextHandler = (event) => {
    if (setStage) {
      setStage(transactionPreFormQuestionsIds.timeline);
    }
    if (currentQuestionId === transactionPreFormQuestionsIds.transactionTemplate) {
      onNext(event);
    }
  };

  const onAnswerHandler = (answer) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.transactionTemplate]: answer,
        [transactionPreFormQuestionsIds.assignParticipantsToRoles]: [],
        [transactionPreFormQuestionsIds.tasks]: [],
      }),
    );
  };

  const onResetHandler = () => dispatch(resetTransactionCreateTemplateEffect());

  const addDateControlMilestones = () => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.timeline]: orderBy(dateControlsMilestones, 'DueDate', [
          'asc',
        ]),
      }),
    );
  };

  return (
    <div testid="apply_transaction_template" className={styles.container}>
      <Question>{heading}</Question>
      <AnswersContainer>
        <Wrapper isPending={isTemplateLoading}>
          <Button
            type="text"
            className={classNames(styles.answerBtn, { [styles.active]: apply === 'yes' })}
            onClick={() => {
              onAnswerHandler('yes');
              setModalOpen(true);
            }}
          >
            Yes
          </Button>
          <Button
            type="text"
            className={classNames(styles.answerBtn, { [styles.active]: apply === 'no' })}
            onClick={() => {
              onResetHandler();
              addDateControlMilestones();
              onAnswerHandler('no');
            }}
          >
            No
          </Button>
        </Wrapper>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onNextHandler} disabled={!apply} />
        {showSkipButton ? <Continue onClick={onNextHandler} variant="skip-hollow" /> : null}
      </ButtonsContainer>
      <Modal
        title={getModalTitle()}
        isOpen={modalOpen}
        onClose={() => {
          onResetHandler();
          setModalOpen(false);
        }}
        className={styles.previewModal}
        modalHeaderClassName={styles.modalHeader}
        contentClassName={styles.modalContent}
        testid="transaction_templates"
      >
        {templateToPreviewId ? (
          <TemplatePreview
            templateId={templateToPreviewId}
            isTransactionTemplate
            getPreviewModalData={getPreviewModaldataHandler}
            className={styles.modalWrapper}
          />
        ) : (
          <Templates
            onPreview={onPreviewTemplate}
            onSelect={onSelectTemplate}
            isTransactionTemplate
            listRef={listRef}
          />
        )}
      </Modal>
    </div>
  );
};

TransactionTemplate.propTypes = {
  onNext: PropTypes.func,
  setStage: PropTypes.func,
  currentQuestionId: PropTypes.string,
  showSkipButton: PropTypes.bool,
};

TransactionTemplate.defaultProps = {
  onNext: () => {},
  currentQuestionId: '',
  showSkipButton: false,
};

export default TransactionTemplate;
