import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Row, Col } from 'antd';

import {
  getTransactionAccessSelector,
  getTransactionSelector,
  getTranslationAdminAccessSelector,
  getKeyDatesSelector,
} from 'store/selectors/transaction';
import { LeftSide, RightSide } from 'components/Transactions';
import { DetailsView } from 'pages/Workshop/Transactions/components/DetailsView';
import {
  PropertyTitle,
  Price,
  ClosingDate,
  Status,
  Timeline,
  NeedsAttention,
  Switcher,
  DropdownValues,
  NamesArray,
} from '..';
import { listedStatusesArray, transactionStatuses } from 'settings/constants/transaction';
import { getUserRoleSelector } from 'store/selectors/user';
import { AGENT, CLIENT } from 'settings/constants/roles';

import styles from './styles.module.scss';
import ProjectTitle from '../ProjectTitle';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { isAfterToday, isToday } from 'helpers';
import { getTransactionEndDate, getTransactionPrice } from 'utils';
import { CancelledDetails } from '../CancelledDetails';

const TransactionView = (props) => {
  const { className } = props;
  const userRole = useSelector(getUserRoleSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const rightSideRef = useRef();
  const { isTransactionAdminOrOwner } = useSelector(getTranslationAdminAccessSelector);
  const keyDates = useSelector(getKeyDatesSelector);
  const isProject = useIsProjectRoute();
  const [dropdownValue, setDropdownValue] = useState(DropdownValues.Overview);
  const [editDetailId, setEditDetailId] = useState();
  const [editId, setEditId] = useState(null);
  const isListed = listedStatusesArray.includes(transaction.Status);
  const isClosed = transaction.Status === transactionStatuses.Closed;
  const isCanceled = transaction.Status === transactionStatuses.Canceled;
  const keyDatesPrices = transaction?.PropertyTransactionKeyDatesPrices;

  // Props that disables other fields when one is opened for modification
  const isDisableField = (editKey) => editId !== null && editId !== editKey;
  const editProps = { setEditId, isDisableField };
  const metaDataColProps = {
    className: styles.colData,
    xs: 24,
    lg: 12,
  };

  const renderProjectSection = ({ ...editProps }) => {
    const getCorrespondingDate = () => {
      const { Status } = transaction;
      return keyDates?.KickoffDate &&
        (isToday(keyDates?.KickoffDate) || isAfterToday(keyDates?.KickoffDate))
        ? keyDates?.KickoffDate
        : Status === transactionStatuses.Closed && keyDates?.CloseDate
        ? keyDates?.CloseDate
        : keyDates?.CompletionDate || keyDates?.ExpiredDate;
    };

    const getCorrespondingLabel = () => {
      return keyDates?.KickoffDate &&
        (isToday(keyDates?.KickoffDate) || isAfterToday(keyDates?.KickoffDate))
        ? 'Kickoff'
        : 'Deadline';
    };

    const getItemToUpdateKey = () => {
      return keyDates?.KickoffDate &&
        (isToday(keyDates?.KickoffDate) || isAfterToday(keyDates?.KickoffDate))
        ? 'KickoffDate'
        : 'CompletionDate';
    };

    return (
      <div className={styles.transactionInfoContainer}>
        <Row className={styles.addressStatusRow} align="middle">
          <Col xs={24} lg={14}>
            <ProjectTitle {...editProps} />
          </Col>
          <Col xs={24} lg={10}>
            <Status editId={editId} />
          </Col>
        </Row>
        <Row>
          <Col {...metaDataColProps}>
            <ClosingDate
              label={getCorrespondingLabel()}
              value={getCorrespondingDate()}
              itemToUpdateKey={getItemToUpdateKey()}
              {...editProps}
            />
          </Col>
          <Col {...metaDataColProps}>
            <NamesArray
              keyToUpdate="agentsOrOwners"
              label="Owner(s)"
              namesArray={transaction?.AgentsOrOwners}
              {...editProps}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const getDateLabel = () => {
    if (isListed) return 'Expires';
    else if (isClosed) return 'Closed';
    else if (isCanceled) return 'Canceled';
    else return 'Closing';
  };

  const getDateItemToUpdate = () => {
    if (isListed) return 'ListingExpireDate';
    else if (isClosed) return 'CloseDate';
    else if (isCanceled) return 'CanceledDate';
    else return 'UnderContractCloseDate';
  };

  const renderTransactionSection = ({ ...editProps }) => {
    return (
      <div className={classNames(styles.transactionInfoContainer)}>
        <Row className={styles.addressStatusRow} align="middle">
          <Col xs={24} lg={14}>
            <PropertyTitle {...editProps} />
          </Col>
          <Col xs={24} lg={10}>
            <Status editId={editId} />
          </Col>
        </Row>
        <Row gutter={20}>
          <Col {...metaDataColProps}>
            <Price
              className={styles.value}
              value={getTransactionPrice(transaction.Status, keyDatesPrices)}
              {...editProps}
            />
            <ClosingDate
              label={getDateLabel()}
              value={getTransactionEndDate(transaction.Status, keyDatesPrices, isListed)}
              itemToUpdateKey={getDateItemToUpdate()}
              {...editProps}
            />
            <NamesArray
              keyToUpdate="transactionClients"
              label="Client(s)"
              namesArray={transaction?.TransactionClients}
              {...editProps}
            />
          </Col>
          <Col {...metaDataColProps}>
            <NamesArray
              keyToUpdate="agentsOrOwners"
              label="Agent(s)"
              namesArray={transaction?.AgentsOrOwners}
              {...editProps}
            />
            <NamesArray
              keyToUpdate="coordinators"
              label="Coordinator(s)"
              namesArray={transaction?.Coordinators}
              {...editProps}
            />
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <div testid="transaction_overview" className={classNames(styles.transactionView, className)}>
      <LeftSide>
        <Switcher dropdownValue={dropdownValue} setDropdownValue={setDropdownValue} />
        {dropdownValue === DropdownValues.Overview ? (
          <>
            <div className={styles.topSideInfo}>
              {isProject
                ? renderProjectSection({ ...editProps })
                : renderTransactionSection({ ...editProps })}
              {isCanceled ? (
                <CancelledDetails
                  ReasonForCancellation={transaction?.ReasonForCancellation}
                  AdditionalCancellationComments={transaction?.AdditionalCancellationComments}
                />
              ) : (
                <NeedsAttention
                  transactionId={transaction?.Id}
                  showFullAccessContent={
                    !isProject &&
                    isListed &&
                    userRole === AGENT &&
                    (fullAccess || isTransactionAdminOrOwner)
                  }
                  showClientContent={userRole === CLIENT}
                />
              )}
            </div>
          </>
        ) : (
          <DetailsView
            editDetailId={editDetailId}
            setEditDetailId={setEditDetailId}
            isProject={isProject}
          />
        )}
      </LeftSide>
      <RightSide ref={rightSideRef}>
        <Timeline rightSideRef={rightSideRef} isProject={isProject} />
      </RightSide>
    </div>
  );
};

TransactionView.propTypes = {
  className: PropTypes.string,
};

TransactionView.defaultProps = {
  className: '',
};

export default TransactionView;
