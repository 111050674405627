import { ConfirmationDialog } from 'components';
import { useMemo } from 'react';
import styles from './styles.module.scss';
import { VoidFormReasonModal } from './VoidFormReasonModal';
import { ICON_VARIANT_TYPE, Icons } from 'pages/Workshop/Forms/Icons';

export const VoidFormModal = (props) => {
  const { modalType, formName, onChangeModalDetails } = props;

  const voidFormConfirmationContent = useMemo(
    () =>
      formName ? (
        <>
          <Icons variant={ICON_VARIANT_TYPE.RETURN_CIRCLE} className={styles.icon} />
          <div className={styles.voidConfirmationContent}>
            Do you want to void
            <br />
            {formName}?
          </div>
        </>
      ) : null,
    [formName],
  );

  const onClose = () => {
    onChangeModalDetails({ modalType: '' });
  };

  return (
    <>
      <ConfirmationDialog
        onReject={onClose}
        onConfirm={() => onChangeModalDetails({ modalType: 'voidReason' })}
        isOpen={modalType === 'confirmation'}
        confirmText={'Confirm'}
        className={styles.voidConfirmationModal}
      >
        {voidFormConfirmationContent}
      </ConfirmationDialog>

      <VoidFormReasonModal
        isOpen={modalType === 'voidReason'}
        onClose={onClose}
        formName={formName}
        formProcessId={props.formProcessId}
      />
    </>
  );
};
