import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';

import {
  MinMax,
  YearMinMax,
  PropertyType,
  Input,
  Select,
  LotSizeMinMax,
  Button,
  Checkbox,
} from 'components';
import { sortStrings, sortStatus } from 'helpers';
import {
  activePropertyStatus,
  propertyStatusOptionsWithoutClosed,
} from 'settings/constants/properties';
import { ValidationSchema } from './validation';

import styles from './styles.module.scss';
import { useEffect } from 'react';
import { mergeInitialValues } from 'pages/Properties/CriteriaModal/Criteria/helpers';
import { getFeedCriteriaSelectorV3 } from 'store/selectors/feedv3';
import { useSelector } from 'react-redux';

const Form = ({ onSubmit, isPending, search, onReset }) => {
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);

  const formik = useFormik({
    initialValues: search || {},
    validationSchema: ValidationSchema,
    onSubmit(values) {
      onSubmit(values);
    },
  });

  useEffect(() => {
    formik.setValues(mergeInitialValues(search, true));
  }, [search]);

  // const parkingOptions = (
  //   <div className={styles.parkingSpaces}>
  //     <div className={styles.row}>
  //       <Checkbox
  //         label="Garage Parking Preferred"
  //         direction={Checkbox.DIRECTION_RIGHT}
  //         checked={formik?.values?.GarageOnly}
  //         onChange={(e) => formik.setFieldValue('GarageOnly', e.target.checked)}
  //       />
  //       <Checkbox
  //         label="RV Parking"
  //         direction={Checkbox.DIRECTION_RIGHT}
  //         // checked={rvParking}
  //         // onChange={(e) => setRVParking(e.target.checked)}
  //       />
  //     </div>
  //     {formik?.values?.GarageOnly && (
  //       <Checkbox
  //         label="Attached Garage Preferred"
  //         direction={Checkbox.DIRECTION_RIGHT}
  //         // checked={attachedGarage}
  //         // onChange={(e) => setAttachedGarage(e.target.checked)}
  //       />
  //     )}
  //   </div>
  // );

  const flatValue = (propName) => formik?.values?.[propName];

  const options = criteria?.Status?.length
    ? propertyStatusOptionsWithoutClosed.filter((option) =>
        criteria?.Status?.includes(option.value),
      )
    : activePropertyStatus;

  return (
    <div>
      <form id="Filter" onSubmit={formik.handleSubmit}>
        <div className={styles.content}>
          {!criteria?.Status?.includes('Closed') && (
            <Select
              name="Status"
              label="Status"
              multiple
              closeOnSelect={false}
              className={{ wrapper: classNames(styles.field, styles.statusField) }}
              options={options}
              value={formik.values.Status}
              onSelect={(event, val) => {
                if (val?.length) val.sort(sortStatus);
                formik.setFieldValue('Status', val);
              }}
              variant={Select.LIGHT_ROUND}
            />
          )}

          <MinMax
            className={styles.field}
            label="Price"
            nameMin="PriceRange.Min"
            nameMax="PriceRange.Max"
            onChangeMin={formik.handleChange}
            onChangeMax={formik.handleChange}
            valueMin={formik.values?.PriceRange?.Min}
            valueMax={formik.values?.PriceRange?.Max}
            placeholderMin="No min"
            placeholderMax="No max"
            prefix="$"
            testidMin="price_min"
            testidMax="price_max"
            variant={Input.LIGHT_ROUND}
            error={formik.errors.PriceRange}
          />

          <PropertyType
            type={'dropdown'}
            className={classNames(styles.field, styles.propertyWrapper)}
            label="Property Type"
            onChange={(type) => {
              if (type?.length) type.sort(sortStrings);
              formik.setFieldValue('HomeType', type);
            }}
            error={formik.touched.HomeType ? formik.errors.HomeType : ''}
            value={formik.values?.HomeType}
            propertyTypeWrapperClassName={styles.filterPropertyWrapper}
            itemClassName={styles.filterItem}
          />

          <MinMax
            className={styles.field}
            label="Beds"
            nameMin="NumBedroomsRange.Min"
            nameMax="NumBedroomsRange.Max"
            onChangeMin={formik.handleChange}
            onChangeMax={formik.handleChange}
            valueMin={formik.values?.NumBedroomsRange?.Min}
            valueMax={formik.values?.NumBedroomsRange?.Max}
            placeholderMin="No min"
            placeholderMax="No max"
            variant={Input.LIGHT_ROUND}
            error={formik.errors.NumBedroomsRange}
          />

          <MinMax
            className={styles.field}
            label="Baths"
            nameMin="NumBathroomsRange.Min"
            nameMax="NumBathroomsRange.Max"
            onChangeMin={formik.handleChange}
            onChangeMax={formik.handleChange}
            valueMin={formik.values?.NumBathroomsRange?.Min}
            valueMax={formik.values?.NumBathroomsRange?.Max}
            placeholderMin="No min"
            placeholderMax="No max"
            variant={Input.LIGHT_ROUND}
            error={formik.errors.NumBathroomsRange}
          />

          <MinMax
            className={styles.field}
            label="Property Size"
            nameMin="SquareFeetRange.Min"
            nameMax="SquareFeetRange.Max"
            onChangeMin={formik.handleChange}
            onChangeMax={formik.handleChange}
            valueMin={formik.values?.SquareFeetRange?.Min}
            valueMax={formik.values?.SquareFeetRange?.Max}
            placeholderMin="No min"
            placeholderMax="No max"
            variant={Input.LIGHT_ROUND}
            error={formik.errors.SquareFeetRange}
          />

          <LotSizeMinMax
            isReset
            className={styles.field}
            label="Lot Size"
            nameMin="LotSizeRange.Min"
            nameMax="LotSizeRange.Max"
            placeholderMin="No min"
            placeholderMax="No max"
            onChangeMin={(val) =>
              formik.setFieldValue('LotSizeRange', {
                ...flatValue('LotSizeRange'),
                Min: val,
              })
            }
            onChangeMax={(val) =>
              formik.setFieldValue('LotSizeRange', {
                ...flatValue('LotSizeRange'),
                Max: val,
              })
            }
            valueMin={formik.values?.LotSizeRange?.Min}
            valueMax={formik.values?.LotSizeRange?.Max}
            variant={Input.LIGHT_ROUND}
            selectClassName={styles.selectWrapper}
          />

          <MinMax
            className={styles.field}
            label="Parking"
            nameMin="ParkingSpacesRange.Min"
            nameMax="ParkingSpacesRange.Max"
            onChangeMin={formik.handleChange}
            onChangeMax={formik.handleChange}
            valueMin={formik.values?.ParkingSpacesRange?.Min}
            valueMax={formik.values?.ParkingSpacesRange?.Max}
            placeholderMin="No min"
            placeholderMax="No max"
            variant={Input.LIGHT_ROUND}
            error={formik.errors.ParkingSpacesRange}
          />
          {/* {parkingOptions} */}

          <YearMinMax
            isReset
            className={styles.field}
            label="Year Build"
            placeholderMin="No min"
            placeholderMax="No max"
            onChangeMin={(val) =>
              formik.setFieldValue('YearBuiltRange', {
                ...flatValue('YearBuiltRange'),
                Min: val,
              })
            }
            onChangeMax={(val) =>
              formik.setFieldValue('YearBuiltRange', {
                ...flatValue('YearBuiltRange'),
                Max: val,
              })
            }
            valueMin={formik.values?.YearBuiltRange?.Min}
            valueMax={formik.values?.YearBuiltRange?.Max}
            variant={Input.LIGHT_ROUND}
          />

          <div className={styles.hoaContainer}>
            {formik.values?.HOARange?.Max !== 0 && (
              <MinMax
                className={styles.field}
                label="HOA"
                nameMin="HOARange.Min"
                nameMax="HOARange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.HOARange?.Min}
                valueMax={formik.values?.HOARange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                variant={Input.LIGHT_ROUND}
                error={formik.errors.HOARange}
              />
            )}
            <div
              className={classNames(styles.checkboxContainer, {
                [styles.padding]: formik.values?.HOARange?.Max !== 0,
              })}
            >
              <Checkbox
                checked={formik.values?.HOARange?.Max === 0}
                onChange={(e) => {
                  const checked = e.target.checked;
                  if (checked) {
                    formik.setFieldValue('NoHOA', true);
                    formik.setFieldValue('HOARange.Min', '');
                    formik.setFieldValue('HOARange.Max', 0);
                  } else {
                    formik.setFieldValue('HOARange.Min', '');
                    formik.setFieldValue('HOARange.Max', '');
                    formik.setFieldValue('NoHOA', false);
                  }
                }}
                label="No HOA"
                direction="right"
                labelTextClassName={styles.label}
              />
            </div>
          </div>

          <MinMax
            className={styles.field}
            thousandSeparator={false}
            label="Stories"
            nameMin="StoriesRange.Min"
            nameMax="StoriesRange.Max"
            onChangeMin={formik.handleChange}
            onChangeMax={formik.handleChange}
            valueMin={formik.values?.StoriesRange?.Min}
            valueMax={formik.values?.StoriesRange?.Max}
            placeholderMin="No min"
            placeholderMax="No max"
            variant={Input.LIGHT_ROUND}
            error={formik.errors.StoriesRange}
          />

          <Input
            name="MaxDaysOnMarket"
            className={styles.field}
            placeholder="No max"
            isNumericString
            isNumberFormat
            label="Days on Market"
            onChange={formik.handleChange}
            value={formik.values.MaxDaysOnMarket}
            variant={Input.LIGHT_ROUND}
          />
        </div>
        <div className={styles.footer}>
          <p className={styles.reset} onClick={onReset}>
            Reset
          </p>
          <Button
            isPending={isPending}
            form="Filter"
            className={styles.submitButton}
            type="submit"
            title="Apply"
            disabled={!formik.isValid}
          />
        </div>
      </form>
    </div>
  );
};

Form.initialValues = {
  Locations: [],
  ParkingSpacesRange: {
    Min: '',
    Max: '',
  },
  GarageOnly: false,
  Status: [],
  MaxDaysOnMarket: '',
  HomeType: [],
  HOARange: { Max: '' },
  NoHOA: false,
  PriceRange: {
    Min: '',
    Max: '',
  },
  NumBedroomsRange: {
    Min: '',
    Max: '',
  },
  NumBathroomsRange: {
    Min: '',
    Max: '',
  },
  SquareFeetRange: {
    Min: '',
    Max: '',
  },
  LotSizeRange: {
    Min: '',
    Max: '',
  },
  YearBuiltRange: {
    Min: '',
    Max: '',
  },
  StoriesRange: {
    Min: '',
    Max: '',
  },
};

Form.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  className: '',
  isPending: false,
  onSubmit: () => {},
};

export default Form;
