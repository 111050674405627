import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClientHeader } from '../ClientFeed/components/ClientHeader';
import ClientProperties from '../ClientFeed/components/ClientProperties';
import { ViewSwitcher } from '../ClientFeed/ViewSwitcher/ViewSwitcher';
import { FilterDrawer } from '../Feed/Drawers';

import {
  getFeedCriteriaSelectorV3,
  getSavedSearchesPropertiesList,
  getSavedSearchesPropertiesMarkersSelector,
} from 'store/selectors/feedv3';
import Map from '../common/Map';
import parentStyles from '../ClientFeed/styles.module.scss';
import { getFeedSortSelector, getSubFilterTypeSelector } from 'store/selectors/feed';
import { useSearchInstanceV3Effect } from '../ClientFeed/hooks/useSearchInstanceV3Effect';
import { dispatch } from 'store';
import { setCriteriaAction, setCriteriaModeAction } from 'store/actions/feedv3';
import { CREATE } from 'settings/constants/mode';
import styles from './styles.module.scss';
import { MapDrawControlsSearchWrapper } from '../SearchResults/components/MapDrawControlsSearchWrapper';
import { LocationType } from 'types';
import { toggleSearchMapDrawingModeAction } from 'store/actions/searchResults';

const ClientResults = () => {
  const {
    properties,
    isPending: savedPropertiesLoading,
    totalCount,
  } = useSelector(getSavedSearchesPropertiesList);
  const propertiesMarkers = useSelector(getSavedSearchesPropertiesMarkersSelector);
  const sort = useSelector(getFeedSortSelector);
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const polygonLocations =
    criteria?.Locations?.filter(({ Type }) => Type === LocationType.Polygon) ?? [];
  const getSearchInstanceV3 = useSearchInstanceV3Effect();

  const [isFavortiesSectionVisible, setIsFavortiesSectionVisible] = useState(false);
  const [viewType, setViewType] = useState('Split View');

  let propertyProps = {
    isPending: savedPropertiesLoading,
    properties: properties || [],
  };

  useEffect(() => {
    if (criteria) {
      const item = {
        criterias: criteria,
      };
      getSearchInstanceV3(item);
    }
  }, [subFilterType, sort]);

  useEffect(() => {
    dispatch(setCriteriaModeAction({ mode: CREATE }));
  }, []);

  const getViewType = (value) => {
    setViewType(value);
  };

  const [showBlurBg, setShowBlurBg] = useState<boolean>(false);

  const setPolygonLocations = (payload) => {
    const nonPolygonLocations =
      criteria?.Locations?.filter(({ Type }) => Type !== LocationType.Polygon) ?? [];
    const newCriteria = { ...criteria, Locations: [...nonPolygonLocations, ...payload] };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    getSearchInstanceV3({ criterias: newCriteria });
  };

  const setDrawnPolygonDispatchFn = (polygon) => {
    const newCriteria = { ...criteria, Locations: [polygon] };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    getSearchInstanceV3({ criterias: newCriteria });
    dispatch(toggleSearchMapDrawingModeAction(false));
  };

  let mapProps = {
    markers: propertiesMarkers || {},
    properties: properties || [],
    totalCount: totalCount || 0,
    setDrawnPolygonDispatchFn: setDrawnPolygonDispatchFn,
    polygonLocations: polygonLocations,
  };

  return (
    <>
      <ClientHeader
        isFavortiesSectionVisible={isFavortiesSectionVisible}
        setIsFavortiesSectionVisible={setIsFavortiesSectionVisible}
        isSavedSearchResult={true}
        setShowBlurBg={(value) => setShowBlurBg(value)}
        isPending={savedPropertiesLoading}
      />
      <Row className={parentStyles.clientFeed}>
        {viewType !== 'Map Only' && (
          <Col
            md={viewType === 'Split View' ? 13 : 24}
            sm={24}
            xs={24}
            className={parentStyles.propertiesContainer}
          >
            <ClientProperties viewType={viewType} {...propertyProps} searchInstanceId={undefined} />
            <FilterDrawer />
          </Col>
        )}
        {viewType !== 'List Only' && (
          <Col
            md={viewType === 'Split View' ? 11 : 24}
            sm={24}
            xs={24}
            className={parentStyles.mapContainer}
          >
            <Map {...mapProps} />
          </Col>
        )}
      </Row>
      <div className={styles.mapSwitchers}>
        <ViewSwitcher getView={getViewType} />
        {viewType !== 'List Only' && (
          <MapDrawControlsSearchWrapper
            polygonLocations={polygonLocations}
            setPolygonLocations={setPolygonLocations}
          />
        )}
      </div>
    </>
  );
};

export default ClientResults;
