import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { FormCopy } from './FormCopy';
import { SignForm } from './SignForm';
import { SmartFormIcons, Spinner } from 'components';
import { ClientOrTransaction } from './ClientOrTransaction';
import { ALL_PRE_FORM_SCREENS, PreFormScreens } from 'app-constants';
import { Button, Col, Progress, Row, Text } from 'components-antd';
import { getSmartFormDataSelector } from 'store/selectors/requestFormProcess';
import { FormPill } from 'pages/FormProcess/components';
import { FillForm } from './FillForm';

import styles from './styles.module.scss';
import { FormDetails } from 'types';

interface PreFormProps {
  pdfName?: string;
  handleDone: () => void;
  handleExit?: () => void;
  clientName?: string;
  clientAddress?: string;
  propertyTransactionId?: number;
  isProject?: boolean;
  handleNameUpdate?: (newName: string) => void;
  customForm?: boolean;
  isTemplate?: boolean;
  isVoidForm?: boolean;
  formDetails?: FormDetails;
  saveAsTemplate?: boolean;
  isBundle?: boolean;
  propertyAddress?: any;
  isLibraryTemplate?: boolean;
  makeFormCopy?: boolean;
}

export const PreForm = ({
  pdfName,
  clientName,
  clientAddress,
  handleExit,
  handleDone,
  propertyTransactionId,
  isProject,
  handleNameUpdate,
  customForm,
  isTemplate,
  isVoidForm,
  formDetails,
  saveAsTemplate,
  isBundle,
  propertyAddress,
  isLibraryTemplate,
  makeFormCopy,
}: PreFormProps) => {
  const [showClientOrTransaction, setShowClientOrTransaction] = useState(true);
  const [loading, setLoading] = useState(true);
  const [allScreens, setAllScreens] = useState([...ALL_PRE_FORM_SCREENS]);

  const [index, setIndex] = useState(0);
  const { isPending, isError, signatoriesPresent, showClientEditorScreen } =
    useSelector(getSmartFormDataSelector);

  useEffect(() => {
    let updatedScreens = [...ALL_PRE_FORM_SCREENS];
    if (customForm) {
      updatedScreens = updatedScreens.filter((s) => s !== PreFormScreens.FillForm);
    } else {
      if (!showClientEditorScreen) {
        updatedScreens = updatedScreens.filter((s) => s !== PreFormScreens.FillForm);
      }

      if (!signatoriesPresent && !isBundle && !isVoidForm) {
        updatedScreens = updatedScreens.filter((s) => s !== PreFormScreens.SignForm);
      }

      if (!showClientOrTransaction) {
        updatedScreens = updatedScreens.filter((s) => s !== PreFormScreens.ClientOrTransaction);
      }
    }
    setAllScreens(updatedScreens);
    setIndex(0);
    setTimeout(() => setLoading(false), 1000);
  }, [showClientEditorScreen, signatoriesPresent, showClientOrTransaction]);

  const handleNextQuestion = () => {
    if (allScreens[index + 1] !== undefined) {
      setIndex(index + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (allScreens[index - 1] !== undefined) {
      setIndex(index - 1);
    }
  };

  const handleContinue = () => {
    if (index + 1 < allScreens.length) {
      checkResponse();
    } else {
      handleDone();
    }
  };

  const checkResponse = () => {
    handleNextQuestion();
  };

  const NavigationInfoRow = () => {
    const allowEdit = !isVoidForm;

    return (
      <Row justify="center" className={styles.navigationInfoRow}>
        <Col className={styles.sideControls}>
          {index > 0 ? (
            <div className={styles.navigationIcons}>
              <div
                onClick={handlePreviousQuestion}
                className={classNames(styles.backNavigation, styles.navigationButton)}
              >
                <SmartFormIcons variant="back" />
              </div>
            </div>
          ) : (
            <></>
          )}
        </Col>

        <Col>
          <FormPill
            clientAddress={clientAddress}
            clientName={clientName}
            pdfName={pdfName}
            updateFormName={handleNameUpdate}
            formPillClass={styles.formPill}
            pdfInfoClass={allowEdit ? styles.preFormPdfInfo : ''}
            pdfNameClass={allowEdit ? styles.pdfNameInformation : ''}
            editablePdfClass={allowEdit ? styles.editablePdfInfo : ''}
            showBundleIcon={allowEdit}
            isBundle={isBundle}
            disableEditName={!allowEdit}
          />
        </Col>

        <Col className={classNames(styles.sideControls, styles.preFormControls)}>
          <div className={styles.navigationIcons}>
            <div
              className={classNames(styles.closeNavigation, styles.navigationButton)}
              onClick={handleExit}
            >
              <SmartFormIcons variant="cancel" />
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const getActiveQuestion = () => {
    switch (allScreens[index]) {
      case PreFormScreens.ClientOrTransaction:
        return (
          <ClientOrTransaction
            propertyTransactionId={propertyTransactionId}
            propertyAddress={propertyAddress}
            isProject={isProject}
            setShowClientOrTransaction={setShowClientOrTransaction}
            onContinue={handleContinue}
          />
        );
      case PreFormScreens.SignForm:
        return (
          <SignForm
            customForm={customForm}
            onContinue={handleContinue}
            saveAsTemplate={saveAsTemplate}
            isTemplate={isTemplate}
            isBundle={isBundle}
            isVoidForm={isVoidForm}
            isLibraryTemplate={isLibraryTemplate}
            makeFormCopy={makeFormCopy}
          />
        );
      case PreFormScreens.FillForm:
        return <FillForm onContinue={handleContinue} />;
      case PreFormScreens.FormCopy:
        return <FormCopy onContinue={handleContinue} />;
      default:
        return <></>;
    }
  };

  const getContainerContent = () => {
    if (isPending || loading) {
      return <Spinner />;
    } else if (isError) {
      return (
        <Text className={styles.errorMessage}>
          There was an issue with loading, please try again.
        </Text>
      );
    } else {
      return (
        <div
          className={classNames(styles.listContainer, {
            [styles.signFormListContainer]: allScreens[index] === PreFormScreens.SignForm,
          })}
        >
          {getActiveQuestion()}
        </div>
      );
    }
  };

  return (
    <>
      <div className={styles.preFormContainer}>
        <Progress
          percent={((index + 1) / allScreens.length) * 100}
          className={styles.progressBar}
        />
        <NavigationInfoRow />
        {getContainerContent()}
      </div>
    </>
  );
};
