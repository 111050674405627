import { ClientAddressEntity } from 'app-constants';
import { get, isUndefined } from 'lodash-es';
import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';
import { preparedAttachment } from 'store/effects/adminPanel/helpers';

export function getInfoByRole({ signUpRole: role, cfg, isPartner, isCommonInfo, user }) {
  switch (role) {
    case AGENT: {
      const validWebsites = cfg.websites
        ? cfg.websites.filter((link) => !!link?.length)
        : undefined;
      const roleInfo =
        cfg.bio !== undefined ||
        validWebsites !== undefined ||
        cfg.city !== undefined ||
        cfg.address !== undefined
          ? {
              [role]: {
                Bio: cfg.bio ?? null,
                Websites: validWebsites ?? [],
                Address: cfg.address
                  ? {
                      Line1: cfg.address?.line1,
                      Line2: cfg.suiteUnit || undefined,
                      City: cfg.address?.city,
                      State: cfg.address?.state,
                      Zip: cfg.address?.zip,
                    }
                  : null,
              },
            }
          : {};
      return {
        ...roleInfo,
        ...(cfg.brokerageName !== undefined || cfg.brokerageAddress !== undefined
          ? {
              Brokerage: {
                Name: cfg.brokerageName || '',
                Address: cfg.brokerageAddress
                  ? {
                      Line1: cfg.brokerageAddress?.line1,
                      Line2: cfg.brokerageSuiteUnit || undefined,
                      City: cfg.brokerageAddress?.city,
                      State: cfg.brokerageAddress?.state,
                      Zip: cfg.brokerageAddress?.zip,
                    }
                  : null,
                Phones: cfg.phone
                  ? [
                      {
                        PhoneNumber: cfg.phone || '',
                        PhoneType: 'Mobile',
                        IsPrimary: true,
                      },
                    ]
                  : [],
              },
            }
          : { Brokerage: undefined }),
        AreasOfOperation: cfg.areasOfOperation,
        ...(cfg.mls !== undefined && { SignupDetails: cfg.mls }),
        TeamName: cfg?.teamName,
      };
    }
    case THIRD_PARTY: {
      const isCompanyInfo =
        cfg?.organization ||
        cfg?.jobTitle ||
        (cfg?.address && Object.keys(cfg?.address).length) ||
        cfg?.suiteUnit;
      const userPartnerData = user[THIRD_PARTY]?.Partner;
      const logo = get(cfg, 'logoUrl');
      const logoAttachment = logo && typeof logo === 'object' ? preparedAttachment(logo) : null;
      const payload = {
        ...(isCommonInfo ? { Bio: cfg?.bio } : {}),
        ...(isCommonInfo && isCompanyInfo && !isPartner
          ? {
              Company: {
                BusinessName: cfg.organization,
                JobTitle: cfg.jobTitle,
                Address: {
                  ...(cfg?.address || {}),
                  Line2: cfg?.suiteUnit || undefined,
                },
                SuiteUnit: cfg.suiteUnit || undefined,
              },
            }
          : {}),
        ...(isPartner
          ? {
              Partner: {
                AdditionalBusinessName: !isUndefined(cfg?.additionalBusinessName)
                  ? cfg?.additionalBusinessName || null
                  : undefined,
                Address: !isUndefined(cfg?.address)
                  ? cfg?.address
                    ? {
                        Line1: get(cfg, 'address.Line1') || undefined,
                        Line2: get(cfg, 'address.Line2') || undefined,
                        City: get(cfg, 'address.City') || undefined,
                        State: get(cfg, 'address.State') || undefined,
                        Zip: get(cfg, 'address.Zip') || undefined,
                        ProviderPlaceId: undefined,
                        PlaceName: undefined,
                        ...(cfg.address?.Geolocation?.Lat && cfg.address?.Geolocation?.Long
                          ? {
                              Geolocation: {
                                Lat: get(cfg.address, 'Geolocation.Lat'),
                                Long: get(cfg.address, 'Geolocation.Long'),
                              },
                            }
                          : {}),
                      }
                    : null
                  : undefined,
                BusinessName: cfg?.businessName ? cfg?.businessName : undefined,
                Description: !isUndefined(cfg?.description) ? cfg?.description : undefined,
                Email: cfg?.partnerEmail ? cfg?.partnerEmail?.toLowerCase() : undefined,
                PhoneNumber: !isUndefined(cfg?.partnerPhone)
                  ? cfg?.partnerPhone || null
                  : undefined,
                SuiteUnit: !isUndefined(cfg?.suiteUnit) ? cfg?.suiteUnit || null : undefined,
                Website: !isUndefined(cfg?.website) ? cfg?.website || null : undefined,
                DirectoryPreference: cfg.DirectoryPreference,
                AreasOfOperation: cfg?.areasOfOperation,
                ...(logoAttachment ? { LogoUrl: logoAttachment } : {}),
              },
            }
          : {}),
      };

      const cleanedPayload = Object.entries(payload).reduce((acc, [key, val]) => {
        if (!val) {
          return { ...acc };
        }
        return { ...acc, [key]: val };
      }, {});

      return {
        ...(Object.keys(cleanedPayload).length ? { [role]: payload } : { [role]: undefined }),
      };
    }
    case CLIENT: {
      const roleInfo =
        cfg.address || cfg.suiteUnit
          ? {
              [role]: {
                Id: user?.Id,
                Address: [
                  {
                    address: cfg.address,
                    placeId: cfg.placeId,
                    ...(cfg.suiteUnit ? { suiteUnit: cfg.suiteUnit } : undefined),
                    isPrimary: true,
                    entityType: ClientAddressEntity.User,
                    addressParsed: cfg?.addressParsed,
                  },
                ],
              },
            }
          : {};

      return { ...roleInfo };
    }
    default:
      return {};
  }
}
