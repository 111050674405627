import { useRef } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { NumberInput, PhoneNumber } from 'components';
import { useTextFitField } from './hooks/useTextFitField';

export const TextFitField = (props) => {
  const {
    isFullName,
    isAddress,
    style,
    onClick,
    itemID,
    textFitType,
    fieldProps = {},
    defaultValue,
    onBlur,
    fieldInitialFontSize,
    isSmallField,
    ...rest
  } = props;

  const inputElementRef: any = useRef<any>();

  const isPhone = textFitType === 'phoneNumber';
  const isDateSigned = textFitType === 'dateSigned';
  const isNumberInput = textFitType === 'numberInput';
  const isDatePicker = textFitType === 'datePicker';

  const { currentFontSize, fieldValue, onFieldBlur, onChange } = useTextFitField({
    inputElementRef,
    defaultValue,
    onBlur,
    persistOldValue: isFullName,
    isPhone,
    isDateSigned,
    isNumberInput,
    initialFontSize: fieldInitialFontSize,
    restrictEmoji: isFullName || isAddress,
  });

  const onTextFitFieldBlur = (event) => {
    onBlur(event, currentFontSize);
  };

  const renderTextFitComponent = () => {
    switch (textFitType) {
      case 'phoneNumber': {
        return (
          <PhoneNumber
            style={{ fontSize: currentFontSize }}
            placeholder="(---) --- -----"
            onUpdate={console.log}
            value={fieldValue}
            onBlur={onTextFitFieldBlur}
            onChange={onChange}
            textFitProps={{ ref: inputElementRef, onBlur: onTextFitFieldBlur, onChange }}
            className={fieldProps.className}
            autoComplete={'off'}
          />
        );
      }
      case 'numberInput': {
        const { isCurrency, requiredClass, className, prefixClass } = fieldProps;
        const inputProps = { ...rest, onBlur: onTextFitFieldBlur };

        return (
          <NumberInput
            value={fieldValue}
            prefix={isCurrency ? '$' : ''}
            prefixClass={isCurrency ? prefixClass : ''}
            onClick={onClick}
            itemID={itemID}
            id={itemID}
            onChange={onChange}
            style={{ fontSize: currentFontSize }}
            textFitProps={{ ref: inputElementRef }}
            {...(isCurrency
              ? inputProps
              : {
                  ...inputProps,
                  className: `${inputProps.className} ${className}`,
                })}
            containerProps={{
              ...inputProps,
              style,
              className: classNames(`${rest.className}`, {
                ...requiredClass,
              }),
            }}
            autoComplete={'off'}
          />
        );
      }

      default: {
        const inputProps = { ...(isFullName && { value: fieldValue }) };

        return (
          <input
            ref={inputElementRef}
            style={{ fontSize: currentFontSize }}
            onClick={onClick}
            itemID={itemID}
            id={itemID}
            onChange={onChange}
            defaultValue={defaultValue}
            placeholder={rest.placeholder}
            onBlur={onFieldBlur}
            disabled={rest.disabled}
            {...inputProps}
            autoComplete={'off'}
          />
        );
      }
    }
  };

  const onFieldClick = (event) => {
    if (isAddress) {
      inputElementRef.current?.focus();
    }

    onClick(event);
  };

  if (isNumberInput) return renderTextFitComponent();

  return (
    <div itemID={itemID} onClick={onFieldClick} {...rest} style={{ ...style }}>
      <div
        className={classNames(
          {
            [styles.textFitInputField]: !isDatePicker,
            [styles.addressTextFitField]: isAddress,
            [styles.smallAddressTextFitField]: isAddress && isSmallField,
          },
          rest.className,
        )}
      >
        {renderTextFitComponent()}
      </div>
    </div>
  );
};
