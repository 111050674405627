import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash-es';
import { formatNumberValue, getFontSize, modifyFontSizeHistory } from '../helper';
import { hasEmoji, removeEmoji } from '../../helper';

const minFontSize = 3;
const phoneDefaultFont = 12;
const minDateFontSize = 5;

export const useTextFitField = (props) => {
  const {
    inputElementRef,
    defaultValue,
    onBlur,
    persistOldValue,
    isPhone,
    isDateSigned,
    isNumberInput,
    initialFontSize,
    restrictEmoji,
  } = props;

  const [currentFontSize, setFontSize] = useState(initialFontSize);
  const [fontSizeHistory, setFontSizeHistory] = useState({});

  const [fieldValue, setFieldValue] = useState(defaultValue);

  useEffect(() => {
    !fieldValue && setFieldValue(defaultValue);

    if (inputElementRef?.current) {
      let fontSize = getFontSize(inputElementRef.current, currentFontSize, initialFontSize, false);
      let valueLength = inputElementRef.current.value?.length;

      if (fontSize !== currentFontSize) {
        const history = modifyFontSizeHistory(
          valueLength,
          fontSizeHistory,
          fontSize,
          initialFontSize,
        );

        setFontSizeHistory(history);
      }

      modifyFontSize(fontSize, valueLength);
    }
  }, [defaultValue, initialFontSize]);

  const onChange = (event) => {
    if (restrictEmoji && hasEmoji(event.target.value)) {
      event.target.value = removeEmoji(event.target.value);
    }

    event.target.value = isNumberInput
      ? formatNumberValue(event.target.value, fieldValue)
      : event.target.value;

    setFieldValue(event.target.value);

    const history = fontSizeHistory;

    const valueLength = event.target.value.length;

    let fontSize = getFontSize(event.target, currentFontSize, initialFontSize);

    if (valueLength) {
      if (history[valueLength]) {
        fontSize = history[valueLength];
        delete history[valueLength];
        setFontSizeHistory(history);
      } else if (fontSize !== currentFontSize) {
        history[valueLength - 4] = currentFontSize;

        setFontSizeHistory(history);
      }
    } else if (!isEmpty(fontSizeHistory)) {
      setFontSizeHistory({});
      fontSize = initialFontSize;
    } else {
      fontSize = initialFontSize;
    }

    fontSize = fontSize < minFontSize ? minFontSize : fontSize;

    setFontSize(fontSize);
  };

  const onFieldBlur = (event) => {
    if (!event.target.value.trim().length && persistOldValue) {
      setFieldValue(defaultValue);

      setTimeout(() => {
        let fontSize = getFontSize(
          inputElementRef.current,
          currentFontSize,
          initialFontSize,
          false,
        );
        const valueLength = inputElementRef.current.value.length;

        const history = modifyFontSizeHistory(
          valueLength,
          fontSizeHistory,
          fontSize,
          initialFontSize,
        );
        fontSize = fontSize < minFontSize ? minFontSize : fontSize;
        setFontSize(fontSize);
        setFontSizeHistory(history);
      }, 200);
    } else {
      onBlur(event, currentFontSize);
    }
  };

  const modifyFontSize = (fontSize, valueLength) => {
    if (isNumberInput && valueLength && valueLength <= 3 && fontSize < 5) {
      fontSize = 11;
    } else {
      fontSize = fontSize < minFontSize ? minFontSize : fontSize;

      fontSize = isPhone && fontSize > phoneDefaultFont ? initialFontSize : fontSize;
      fontSize = isDateSigned && fontSize < minDateFontSize ? 7 : fontSize;
    }

    setFontSize(fontSize);
  };

  return { currentFontSize, fieldValue, onFieldBlur, onChange };
};
