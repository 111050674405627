import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Modal } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { uploadFilePopupModes } from 'settings/constants/common';
import Options from '../Options';
import Uploaded from '../Uploaded';

import styles from './styles.module.scss';

const DocumentsModal = (props) => {
  const {
    modal,
    files,
    onCloseModal,
    onOptionFileChange,
    onSave,
    isPending,
    addMore,
    addMoreFile,
    addDragInModal,
    docCategoryName,
    accept,
  } = props;
  const {
    withCategory,
    isOnlyUploading,
    multiple,
    withPermissions,
    isCategoryOptional,
    dropzone,
    fileNameRequired,
    filesRequired,
    vaultFile,
    isPreapprovals,
  } = props;
  useEffect(() => {
    if (modal.mode !== uploadFilePopupModes.uploading && addMore) {
      addMoreFile(files);
      onCloseModal();
    }
  }, [modal.mode]);

  return (
    <Modal
      className={styles.documentsModal}
      isOpen={modal.open}
      onClose={onCloseModal}
      title={
        modal.mode === uploadFilePopupModes.uploading
          ? `Upload File${multiple ? 's' : ''}`
          : `Uploaded File${multiple ? 's' : ''}`
      }
      testid="upload_modal"
      modalTitleClassName={styles.documentsModalTitle}
    >
      <div className={styles.modalContent}>
        {modal.mode === uploadFilePopupModes.uploading ? (
          <Options
            onOptionFileChange={onOptionFileChange}
            isOnlyUploading={isOnlyUploading}
            multiple={multiple}
            addDragInModal={addDragInModal}
            onCloseModal={onCloseModal}
            accept={accept}
          />
        ) : (
          <Uploaded
            files={files}
            onSave={onSave}
            onCloseModal={onCloseModal}
            isPending={isPending}
            withCategory={withCategory}
            withPermissions={withPermissions}
            isCategoryOptional={isCategoryOptional}
            docCategoryName={docCategoryName}
            dropzone={dropzone}
            fileNameRequired={fileNameRequired}
            filesRequired={filesRequired}
            vaultFile={vaultFile}
            isPreapprovals={isPreapprovals}
            multiple={multiple}
          />
        )}
      </div>
    </Modal>
  );
};

DocumentsModal.propTypes = {
  className: PropTypes.string,
  modal: PropTypes.shape({
    mode: PropTypes.string,
    open: PropTypes.bool,
  }),
  files: PropTypes.arrayOf(PropTypes.shape({})),
  onCloseModal: PropTypes.func,
  onOptionFileChange: PropTypes.func,
  onSave: PropTypes.func,
  isPending: PropTypes.bool,
  isOnlyUploading: PropTypes.bool,
  withCategory: PropTypes.bool,
  multiple: PropTypes.bool,
  withPermissions: PropTypes.bool,
  isCategoryOptional: PropTypes.bool,
  docCategoryName: PropTypes.string,
  dropzone: PropTypes.bool,
  fileNameRequired: PropTypes.bool,
  filesRequired: PropTypes.bool,
  vaultFile: PropTypes.bool,
  isPreapprovals: PropTypes.bool,
};

DocumentsModal.defaultProps = {
  className: '',
  modal: {},
  files: {},
  onCloseModal: () => {},
  addMoreFile: () => {},
  addMore: false,
  onOptionFileChange: () => {},
  onSave: () => {},
  isPending: false,
  isOnlyUploading: false,
  withCategory: true,
  multiple: true,
  withPermissions: false,
  isCategoryOptional: false,
  addDragInModal: undefined,
  docCategoryName: '',
  dropzone: false,
  fileNameRequired: false,
  filesRequired: false,
  vaultFile: false,
  isPreapprovals: false,
};

export default DocumentsModal;
