import { cleanSearchRangeObj } from 'helpers';
import { noRanges } from 'settings/constants/common';

export function cleanSearchQueryObj(config) {
  const {
    Locations,
    HomeType,
    MaxDaysOnMarket,
    MinParkingSpaces,
    GarageOnly,
    Status,
    NumBedroomsRange,
    NumBathroomsRange,
    YearBuiltRange,
    LotSizeRange,
    AmenitiesPrefs,
    HomePrefs,
    ViewPrefs,
    NeighborhoodPrefs,
    InteriorStylesPrefs,
    OutdoorPrefs,
    Parking,
    CommutePrefs,
    Schools,
    saveSearchId,
    searchInstanceId,
    ParkingSpacesRange,
    KeywordPrefs,
    NoHOA,
  } = config ?? {};

  return {
    ...(Locations?.length ? { Locations } : { Locations: undefined }),
    ...(HomeType?.length ? { HomeType } : { HomeType: undefined }),
    ...(MaxDaysOnMarket ? { MaxDaysOnMarket: +MaxDaysOnMarket } : { MaxDaysOnMarket: undefined }),
    ...(MinParkingSpaces ? { MinParkingSpaces } : { MinParkingSpaces: undefined }),
    ...(GarageOnly ? { GarageOnly } : { GarageOnly: undefined }),
    ...(Status?.length
      ? { Status: Status?.map((status) => status?.value || status) }
      : { Status: undefined }),
    ...(NoHOA ? { NoHOA } : { NoHOA: undefined }),
    ...(config?.['HOARange']?.Max === 0
      ? { HOARange: { Max: 0 } }
      : cleanSearchRangeObj(config, 'HOARange')),
    ...cleanSearchRangeObj(config, 'NumBathroomsRange'),
    ...cleanSearchRangeObj(config, 'NumBedroomsRange'),
    ...cleanSearchRangeObj(config, 'PriceRange'),
    ...cleanSearchRangeObj(config, 'SquareFeetRange'),
    ...cleanSearchRangeObj(config, 'StoriesRange'),
    ...((config?.YearBuiltRange?.Min !== noRanges.noMin && YearBuiltRange?.Min) ||
    (YearBuiltRange?.Max !== noRanges.noMax && YearBuiltRange?.Max)
      ? {
          YearBuiltRange: {
            Min:
              YearBuiltRange?.Min !== noRanges.noMin && YearBuiltRange?.Min
                ? +YearBuiltRange?.Min
                : undefined,
            Max:
              YearBuiltRange?.Max !== noRanges.noMax && YearBuiltRange?.Max
                ? +YearBuiltRange?.Max
                : undefined,
          },
        }
      : { YearBuiltRange: undefined }),

    ...((config?.LotSizeRange?.Min !== noRanges.noMin && LotSizeRange?.Min) ||
    (LotSizeRange?.Max !== noRanges.noMax && LotSizeRange?.Max)
      ? {
          LotSizeRange: {
            Min:
              LotSizeRange?.Min !== noRanges.noMin && LotSizeRange?.Min
                ? +LotSizeRange?.Min
                : undefined,
            Max:
              LotSizeRange?.Max !== noRanges.noMax && LotSizeRange?.Max
                ? +LotSizeRange?.Max
                : undefined,
          },
        }
      : { LotSizeRange: undefined }),

    ...((config?.ParkingSpacesRange?.Min !== noRanges.noMin && ParkingSpacesRange?.Min) ||
    (ParkingSpacesRange?.Max !== noRanges.noMax && ParkingSpacesRange?.Max)
      ? {
          ParkingSpacesRange: {
            Min:
              ParkingSpacesRange?.Min !== noRanges.noMin && ParkingSpacesRange?.Min
                ? +ParkingSpacesRange?.Min
                : undefined,
            Max:
              ParkingSpacesRange?.Max !== noRanges.noMax && ParkingSpacesRange?.Max
                ? +ParkingSpacesRange?.Max
                : undefined,
          },
        }
      : { ParkingSpacesRange: undefined }),

    ...(KeywordPrefs?.length ? { KeywordPrefs } : { KeywordPrefs: undefined }),
    ...(AmenitiesPrefs?.length ? { AmenitiesPrefs } : { AmenitiesPrefs: undefined }),
    ...(HomePrefs?.length ? { HomePrefs } : { HomePrefs: undefined }),
    ...(ViewPrefs?.length ? { ViewPrefs } : { ViewPrefs: undefined }),
    ...(InteriorStylesPrefs?.length ? { InteriorStylesPrefs } : { InteriorStylesPrefs: undefined }),
    ...(NeighborhoodPrefs?.length
      ? //TODO: Remove once school functionality is implemented in onboarding
        { NeighborhoodPrefs: NeighborhoodPrefs?.filter((val) => val.Preference !== 'GoodSchools') }
      : { NeighborhoodPrefs: undefined }),
    ...(OutdoorPrefs?.length ? { OutdoorPrefs } : { OutdoorPrefs: undefined }),
    ...(Parking ? { Parking } : { Parking: undefined }),
    ...(CommutePrefs?.length ? { CommutePrefs } : { CommutePrefs: undefined }),
    ...(Schools ? { Schools } : { Schools: undefined }),
    ...(saveSearchId ? { SavedSearchId: saveSearchId } : {}),
    ...(searchInstanceId ? { SearchInstanceId: searchInstanceId } : {}),
  };
}
