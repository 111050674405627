import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { ArrowDownFull } from 'components/Icons';
import { Avatar } from 'components';
import { transactionRoles, Role } from 'settings/constants/roles';
import { Icons } from 'pages/Workshop/Icons';
import {
  displayListedStatus,
  listedStatusesArray,
  transactionStatuses,
} from 'settings/constants/transaction';
import Notifications from '../Notifications/Notifications';

import styles from './styles.module.scss';
import { Status } from '../Status';
import { getInitials } from 'utils';
import { getDateOnly, isBeforeToday } from 'helpers';
import { NamesContainer } from '../NamesContainer';
import NumberFormat from 'react-number-format';
import { formatCurrency } from './helper';

interface DataType {
  id: number;
  key: React.Key;
  client: string;
  transaction: string;
  status: string;
  price: number;
  tasks: string;
  totalTasks?: number;
  tasksAmount: number;
  closingDate: string;
  data: any;
  overdueTasksCount: number;
  imageUrl: string;
  imagePlaceholder: string;
  address: string;
  title: string;
  name: string;
}

const sortableColumnTitle =
  (titleLabel: string, key: string) =>
  ({ sortColumns }) => {
    const sortedColumn = sortColumns?.find(({ column }) => column.key === key);
    const sortAsc = sortedColumn?.order === 'ascend';
    return (
      <div className={styles.sortedColumnTitle}>
        {titleLabel}
        {sortedColumn?.order && (
          <ArrowDownFull
            className={classNames(styles.sorterIcon, { [styles.sorterUp]: sortAsc })}
          />
        )}
      </div>
    );
  };

function inferRoleCategory(row): string {
  const roles = row.data?.RepresentingRoles || [];
  return roles.includes(transactionRoles.SELLER) && roles.includes(transactionRoles.BUYER)
    ? 'Dual'
    : roles.includes(transactionRoles.SELLER)
    ? 'Listing'
    : 'Purchase';
}

const EmptyPlaceholderAvatar = () => (
  <div className={styles.clientField}>
    <Avatar avatarClassName={styles.avatar} src={null} placeholder={null} />
    <span>None</span>
  </div>
);

function inferStatus(row): string {
  return listedStatusesArray.includes(row?.status) ? displayListedStatus[row?.status] : row?.status;
}

export function tableColumns(userRole: string, closingColumnLabel?: string): ColumnsType<DataType> {
  const clientColumn = {
    title: 'Client(s)',
    key: 'client',
    dataIndex: 'title',
    render: (title, { data }) => {
      const { TransactionClients } = data ?? {};
      if (!TransactionClients || !TransactionClients?.length) return <EmptyPlaceholderAvatar />;

      return (
        <div className={styles.clientField}>
          <Avatar
            avatarClassName={styles.avatar}
            src={null}
            placeholder={getInitials(TransactionClients?.[0])}
          />
          <div>
            <NamesContainer names={TransactionClients} maxDisplayCount={1} isWidthRestrict />
          </div>
        </div>
      );
    },
  };

  const columns: ColumnsType<DataType> = [
    {
      title: sortableColumnTitle('Transaction', 'transaction'),
      key: 'transaction',
      dataIndex: 'address',
      render: (_, { data }) => {
        const address = data?.Property?.Address;
        return (
          <div className={styles.transaction}>
            <Icons variant={Icons.OFFER} className={styles.icon} />
            <div className={styles.info}>
              <span className={styles.line1}>{address?.Line1}</span>
              <span className={styles.line2}>
                {address?.City + `, ` + address?.State + ` ` + address?.Zip}
              </span>
            </div>
          </div>
        );
      },
      sorter: (a, b) => a.address?.localeCompare(b.address),
    },
    {
      title: sortableColumnTitle('Price', 'price'),
      key: 'price',
      dataIndex: 'price',
      render: (price) => (
        <div className={styles.priceText} title={formatCurrency(price, '$')}>
          <NumberFormat thousandSeparator displayType="text" value={price} prefix="$" />
        </div>
      ),
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: sortableColumnTitle('Type', 'category'),
      key: 'category',
      sorter: (a, b) => inferStatus(a).localeCompare(inferStatus(b)),
      render: (_, row) => (
        <div>
          <p className={styles.roleCategory}>{inferRoleCategory(row)}</p>
          <p className={styles.status}>{inferStatus(row)}</p>
        </div>
      ),
    },
    {
      title: 'Agent(s)',
      key: 'agent',
      dataIndex: 'name',
      render: (_, { data }) => {
        const { AgentsOrOwners } = data ?? {};
        if (!AgentsOrOwners || !AgentsOrOwners?.length) return <span>-</span>;

        return (
          <div className={styles.clientField}>
            <div>
              <NamesContainer names={AgentsOrOwners} maxDisplayCount={1} isWidthRestrict />
            </div>
          </div>
        );
      },
    },
    {
      title: 'Coordinator(s)',
      key: 'coordinator',
      dataIndex: 'coordinator',
      render: (_, { data }) => {
        const { Coordinators } = data ?? {};
        if (!Coordinators || !Coordinators?.length) return <span>-</span>;

        return (
          <div className={styles.clientField}>
            <div>
              <NamesContainer names={Coordinators} maxDisplayCount={1} isWidthRestrict />
            </div>
          </div>
        );
      },
    },
    {
      title: sortableColumnTitle(closingColumnLabel || 'Closing/Expires', 'closingDate'),
      key: 'closingDate',
      dataIndex: 'closingDate',
      render: (endDate) => {
        if (endDate && new Date(endDate) < new Date()) {
          return <span className={styles.closeClosingDate}>{endDate}</span>;
        } else {
          return <span>{endDate}</span>;
        }
      },
      sorter: (a, b) => {
        if (a.closingDate === 'N/A') return 1;
        if (b.closingDate === 'N/A') return -1;

        return moment(a.closingDate).diff(moment(b.closingDate), 'days');
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: '',
      key: 'notifications',
      dataIndex: 'notifications',
      render: (_, row) =>
        row?.data?.isNotificationVisible && (
          <Notifications transactionData={row?.data} userRole={userRole} />
        ),
    },
  ];

  const agentColumns = [...columns.slice(0, 2), clientColumn, ...columns.slice(2)];
  return userRole === Role.Client ? columns : agentColumns;
}

export function projectTableColumns(isArchive?: boolean, userRole?: string): ColumnsType<DataType> {
  return [
    {
      title: sortableColumnTitle('Project', 'project'),
      key: 'project',
      dataIndex: 'name',
      render: (_, { data }) => {
        const name = data?.Name;
        return (
          <div className={styles.transaction}>
            <Icons
              variant={Icons.PROJECT_DOCUMENT}
              className={classNames(styles.icon, styles.pIcon)}
            />
            <div className={styles.name}>
              <span className={styles.line1}>{name}</span>
            </div>
          </div>
        );
      },
      sorter: (a, b) => a.name?.localeCompare(b.name),
      defaultSortOrder: 'descend',
    },
    {
      title: sortableColumnTitle('Status', 'status'),
      key: 'status',
      dataIndex: 'status',
      width: 200,
      render: (status, row) => <Status status={status} />,
      sorter: (a, b) => inferStatus(a)?.localeCompare(inferStatus(b)),
    },
    {
      title: 'Owner(s)',
      key: 'owner',
      width: 200,
      dataIndex: 'owner',
      render: (title, { data }) => {
        const { AgentsOrOwners } = data ?? {};
        if (!AgentsOrOwners || !AgentsOrOwners?.length) return <EmptyPlaceholderAvatar />;

        return (
          <div className={styles.clientField}>
            <Avatar
              avatarClassName={styles.avatar}
              src={null}
              placeholder={getInitials(AgentsOrOwners?.[0])}
            />
            <div>
              <NamesContainer names={AgentsOrOwners} maxDisplayCount={1} />
            </div>
          </div>
        );
      },
    },
    {
      title: sortableColumnTitle('Category', 'transactionCategory'),
      key: 'transactionCategory',
      sorter: (a, b) => a.title?.localeCompare(b.title),
      dataIndex: 'category',
      render: (title, { data }) => {
        const name = data?.TransactionCategory?.Category;
        return <span>{name}</span>;
      },
    },
    {
      title: sortableColumnTitle(isArchive ? 'Dispositioned' : 'Date', 'closingDate'),
      key: 'closingDate',
      dataIndex: 'closingDate',
      render: (_, { data }) => {
        const keyDates = data?.ProjectKeyDates;
        if (data?.Status === transactionStatuses.Upcoming) {
          return (
            <div className={styles.projectDate}>
              <span>{getDateOnly(keyDates?.KickoffDate, 'M/D/YYYY')}</span>
              <span className={styles.projectDateLabel}>Kickoff</span>
            </div>
          );
        } else {
          return (
            <div className={styles.projectDate}>
              <span
                className={classNames({
                  [styles.closeClosingDate]: isBeforeToday(keyDates?.CompletionDate),
                })}
              >
                {keyDates?.CompletionDate
                  ? getDateOnly(keyDates?.CompletionDate, 'M/D/YYYY')
                  : 'N/A'}
              </span>
              <span className={styles.projectDateLabel}>Deadline</span>
            </div>
          );
        }
      },
      sorter: (a, b) => {
        const keyDates1 = a?.data?.ProjectKeyDates;
        const endDate1 = keyDates1?.CompletionDate;
        const keyDates2 = b?.data?.ProjectKeyDates;
        const endDate2 = keyDates2?.CompletionDate;
        if (endDate1 === 'N/A') return 1;
        if (endDate2 === 'N/A') return -1;

        return moment(endDate1).diff(moment(endDate2), 'days');
      },
    },
    {
      title: '',
      key: 'notifications',
      dataIndex: 'notifications',
      render: (_, row) => <Notifications transactionData={row?.data} userRole={userRole} />,
    },
  ];
}
