import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get, cloneDeep } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetClientDetailsAction,
  requestUpdateClientDetailsAction,
} from 'store/actions/clientDetail';
import {
  deleteSearchInstanceDrawerAction,
  setRenameSearchInstanceDrawerAction,
  setStatusSearchInstanceDrawerAction,
} from 'store/actions/drawerActiveSearches';
const initialData = {
  state: IDLE,
  data: [],
  meta: {},
};

export default handleActions(
  {
    [requestGetClientDetailsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestUpdateClientDetailsAction]: (state, { payload }) => {
      const user = cloneDeep(state.data);
      const data = get(payload, ['data', 'result']);
      if (data?.Email) user.Email = data.Email;
      if (data?.Phones) user.Phones = data.Phones;
      if (data?.FirstName) user.FirstName = data.FirstName;
      if (data?.LastName) user.LastName = data.LastName;
      return {
        ...state,
        data: user,
      };
    },
    [setStatusSearchInstanceDrawerAction]: (state, { payload }) => {
      const user = cloneDeep(state.data);
      const index = user?.Client?.SearchInstances.findIndex((item) => item.Id === payload.id);
      user.Client.SearchInstances[index].Status = payload.status;
      return {
        ...state,
        data: user,
      };
    },
    [setRenameSearchInstanceDrawerAction]: (state, { payload }) => {
      const user = cloneDeep(state.data);
      const index = user?.Client?.SearchInstances.findIndex((item) => item.Id === payload.id);
      user.Client.SearchInstances[index].Name = payload.name;
      return {
        ...state,
        data: user,
      };
    },
    [deleteSearchInstanceDrawerAction]: (state, { payload }) => {
      const instances = state.data.filter((item) => item.Id !== payload.id) || [];

      return {
        ...state,
        data: instances,
      };
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
