import { Spinner } from 'components';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { link } from 'settings/navigation/link';
import { routes } from 'settings/navigation/routes';
import {
  createDynamicBundleFormEffect,
  createDynamicFormEffect,
  createFormCopyEffect,
  createNewFormEffect,
  getAgentFormDataEffect,
  getAgentFormRolesEffect,
  getBundleFormRolesEffect,
  getFormMetaEffect,
  getSmartFormDataEffect,
  resetPreFormEffect,
  resetSmartFormDataEffect,
  updateFormRoleEffect,
  updatePreFormResponseEffect,
} from 'store/effects/formProcess';
import {
  getRequestFormProcessSelector,
  getSmartFormDataSelector,
} from 'store/selectors/requestFormProcess';
import { FormIdsType } from 'types';
import { PreForm } from '../../components';
import { getFormPayload } from '../../helper';
import { FORM_USER_ROLE } from 'app-constants';
import { getPropertyAddress } from 'helpers';
import { prepareVoidFormData } from './helper';
import { isArray } from 'lodash-es';
import { beginSigningFormTask } from 'api/templates';

import styles from '../styles.module.scss';

export const CreateRequest = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { state: formState } = useLocation<FormIdsType>();

  const state = useMemo(() => formState, []);
  const {
    isPending,
    formName: defaultFormName,
    preForm,
    formRoles,
  } = useSelector(getRequestFormProcessSelector);

  const { defaultEditors } = useSelector(getSmartFormDataSelector);

  const {
    clientName,
    clientAddress,
    address,
    signatories,
    clientEditorRole,
    formName: newFormName,
    isProject,
  } = preForm;

  const formName = newFormName || defaultFormName;

  useEffect(
    () => () => {
      if (formState) {
        dispatch(resetPreFormEffect());
        dispatch(resetSmartFormDataEffect());
      }
    },
    [],
  );

  const shouldExitCustomForm = () =>
    state?.customForm && (!!state?.documentLink || !!state?.templateId || !!state?.formBundle);

  const fetchMetaEffect = () => dispatch(getFormMetaEffect({ isProject }));

  useEffect(() => {
    if (state?.customForm) {
      if (shouldExitCustomForm()) {
        fetchMetaEffect();
        return;
      }
    } else if (state?.createFormCopy) {
      fetchMetaEffect();
      return;
    } else {
      if (state?.formId && state?.versionId) {
        dispatch(getFormMetaEffect({ isProject }));

        if (state?.type === 'Agent') {
          dispatch(getAgentFormDataEffect({ ...state }));
        } else dispatch(getSmartFormDataEffect({ ...state }));
        return;
      } else if (state?.bundleId || state?.formTemplateIds) {
        fetchMetaEffect();

        return;
      }
    }

    handleExit();
  }, []);

  useEffect(() => {
    if (defaultEditors?.length) {
      const updatedEditors = {
        agent: [],
        client: [],
      };
      defaultEditors.forEach((editor) => {
        updatedEditors[String(editor.FormRole).toLowerCase()].push(editor);
      });
      dispatch(
        updatePreFormResponseEffect({
          editors: updatedEditors,
        }),
      );
    }
  }, [defaultEditors]);

  useEffect(() => {
    if (
      (state?.customForm && !formName && state?.name) ||
      (state?.bundleId && state?.name) ||
      (state?.isLibraryTemplate && state?.name)
    ) {
      handleNameUpdate(state?.name);
    }

    if (state?.customForm && state?.saveAsTemplate) {
      dispatch(getAgentFormRolesEffect());
    }

    if (state?.bundleId && !state?.customForm) {
      dispatch(getBundleFormRolesEffect(state?.bundleId));
    }
  }, [state?.customForm]);

  useEffect(() => {
    if (state && state?.createFormCopy && state?.formDetails) {
      const { formDetails } = state;
      const {
        formRoles,
        signatories,
        clientIds,
        clientName,
        copyRecipients = [],
        transactionId,
        clientAddress,
        address,
      } = prepareVoidFormData(formDetails);

      dispatch(updateFormRoleEffect({ formRoles }));

      dispatch(
        updatePreFormResponseEffect({
          editors: [],
          signatories,
          copyRecipients,
          clientIds,
          clientName,
          transactionId: transactionId || undefined,
          clientAddress: clientAddress || undefined,
          address: address || undefined,
          isSequentialSignature: formDetails?.isSequential,
        }),
      );
    }
  }, [state?.createFormCopy]);

  const handleExit = () => {
    if (state?.transactionId || state?.propertyTransactionId) {
      let documentLink = isProject ? routes.projectDocuments : routes.transactionDocuments;
      documentLink = state?.propertyTransactionId ? routes.transactionTasks : documentLink;
      const transactionId = state?.propertyTransactionId || state?.transactionId;

      history.replace(documentLink.replace(':id', String(transactionId)));
    } else {
      history.replace(routes.workshopForms);
    }

    dispatch(resetPreFormEffect());
    dispatch(resetSmartFormDataEffect());
  };

  const manageTaskId = (formProcessPublicId) => {
    state?.taskId && beginSigningFormTask({ formProcessPublicId }, { taskId: state.taskId });
  };

  const handlePreFormFinish = () => {
    const { saveAsTemplateFormRolesIds, ...restPreForm } = preForm;
    const formData = { ...restPreForm, ...(newFormName?.length ? { formName: newFormName } : {}) };

    const createDynamicForm =
      state?.customForm || state?.templateId || state?.bundleId || state?.createFormCopy;

    if (createDynamicForm) {
      const shouldExit = !(
        state?.documentLink ||
        state?.templateId ||
        state?.formBundle ||
        state?.createFormCopy
      );

      if (shouldExit) {
        return history.replace(routes.workshopForms);
      }

      const dynamicFormPayload = getFormPayload(
        {
          ...formData,
          templateId: state?.templateId,
          documentLink: state?.documentLink,
          customFormRoles:
            state?.templateId || state?.isLibraryTemplate
              ? formRoles.map((role) => {
                  if (role.Name) role.Name = '';
                  return role;
                })
              : formRoles,
          documentIds: state?.documentIds,
          saveAsTemplate: state?.saveAsTemplate,
          saveAsTemplateFormRolesIds,
          bundleId: state?.bundleId,
          formProcessId: state?.formProcessId,
          isLibraryTemplate: state?.isLibraryTemplate,
          ...(state?.createFormCopy && { formName: state?.name }),
        },
        { formIds: state },
        true,
      );

      let navigationState = {
        ...(state?.saveAsTemplate && {
          state: {
            saveAsTemplate: true,
            categories: state?.categories,
            publishToTeam: state?.publishToTeam,
            templateRoleIds: saveAsTemplateFormRolesIds,
          },
        }),
      };

      let hasMultipleDocuments = isMultipleDocuments();

      if (state?.formBundle || hasMultipleDocuments) {
        const { formName, ...restPayload } = dynamicFormPayload;
        delete restPayload.documentLink;

        const bundlePayload = {
          ...restPayload,
          bundleName: formName || '',
        };

        dispatch(
          createDynamicBundleFormEffect(bundlePayload, (err, response) => {
            if (!err) {
              const { FormProcess, FormDocuments, FormBundleName } = response.data.value;
              const { PublicId: formProcessPublicId } = FormProcess[0];
              manageTaskId(formProcessPublicId);
              history.push({
                pathname: link.toWorkshopDynamicFormBundle({
                  formProcessPublicId,
                }),
                state: {
                  ...(navigationState.state || {}),
                  formBundle: true,
                  allDocuments: FormDocuments,
                  bundleId: state?.bundleId,
                  bundleName: FormBundleName || state?.bundleTemplateName || '',
                  proceedToSignMode: state?.isLibraryTemplate,
                },
              });
            }
          }),
        );
      } else if (state?.createFormCopy) {
        dispatch(
          createFormCopyEffect(dynamicFormPayload, (err, response) => {
            if (!err) {
              const { PublicId, FormDocuments } = response.data.value;
              const isMultipleDocuments = FormDocuments.length > 1;

              const pathname = isMultipleDocuments
                ? link.toWorkshopDynamicFormBundle({
                    formProcessPublicId: PublicId,
                  })
                : link.toWorkshopDynamicFormDocument({
                    formProcessPublicId: PublicId,
                    formDocumentPublicId: FormDocuments[0].PublicId,
                  });

              history.push({
                pathname,
                state: {
                  ...(navigationState.state || {}),
                  formBundle: isMultipleDocuments,
                  allDocuments: FormDocuments,
                  ...(isMultipleDocuments && { bundleName: state?.name || '' }),
                  proceedToSignMode: true,
                },
              });
            }
          }),
        );
      } else {
        dispatch(
          createDynamicFormEffect(dynamicFormPayload, (err, response) => {
            if (!err) {
              const { formProcessPublicId, formDocumentPublicId } = response.data.result;
              manageTaskId(formProcessPublicId);
              history.push({
                pathname: link.toWorkshopDynamicFormDocument({
                  formProcessPublicId,
                  formDocumentPublicId,
                }),
                state: {
                  ...(navigationState.state || {}),
                  proceedToSignMode: state?.isLibraryTemplate,
                },
              });
            }
          }),
        );
      }

      // TODO: Create Custom form
    } else if (state?.formId && state?.versionId) {
      if (!formData.editors) {
        formData.editors = {
          client: [],
          agent: [],
        };
      }
      if (clientEditorRole && signatories?.[clientEditorRole]) {
        const userId = signatories?.[clientEditorRole].UserId;

        if (userId) {
          formData.editors.client = [
            {
              FormRole: FORM_USER_ROLE.Client,
              UserId: userId,
            },
          ];
        }
      }

      dispatch(
        createNewFormEffect(getFormPayload(formData, { formIds: state }), (err, response) => {
          if (!err) {
            const { formProcessPublicId, formDocumentPublicId } = response.data.result;
            manageTaskId(formProcessPublicId);
            history.push(
              link.toWorkshopFormProcessDocument({
                formProcessPublicId,
                formDocumentPublicId,
              }),
            );
          }
        }),
      );
    }
  };

  const handleNameUpdate = (newName) => {
    dispatch(
      updatePreFormResponseEffect({
        formName: newName,
      }),
    );
  };

  const getFormattedAddress = () => {
    if (clientAddress) return clientAddress;
    if (address) return getPropertyAddress(address);
    return undefined;
  };

  const isMultipleDocuments = () => {
    return Boolean(
      state?.documentLink && isArray(state?.documentLink) && state?.documentLink.length > 1,
    );
  };

  return (
    <div className={styles.container}>
      {isPending ? (
        <Spinner />
      ) : (
        <PreForm
          customForm={state?.customForm}
          isTemplate={state?.isTemplate}
          isBundle={state?.formBundle || isMultipleDocuments()}
          saveAsTemplate={state?.saveAsTemplate}
          pdfName={formName}
          clientName={clientName}
          clientAddress={getFormattedAddress()}
          handleDone={handlePreFormFinish}
          handleExit={handleExit}
          propertyTransactionId={state?.propertyTransactionId || state?.transactionId}
          propertyAddress={
            state?.propertyAddress ||
            (!state?.formDetails?.transactionId && state?.formDetails?.propertyAddress)
          }
          isProject={isProject}
          handleNameUpdate={handleNameUpdate}
          isVoidForm={state?.voidedForm}
          makeFormCopy={state?.createFormCopy}
          formDetails={state?.formDetails}
          isLibraryTemplate={state?.isLibraryTemplate}
        />
      )}
    </div>
  );
};
