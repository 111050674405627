import { ClientsType, PropertyTransaction } from 'types';
import { ClientCategory } from 'types/client';

export const getClientNameStringAndCount = (invitedClients, uninvitedClients?: string[]) => {
  const invitedClientsLength = invitedClients?.length;
  const uninvitedClientsLength = uninvitedClients?.length;

  let totalClients = 0;
  let clients = '';
  if (invitedClientsLength) {
    totalClients = invitedClientsLength;
    clients = invitedClients
      ?.map(
        (invitedClient) =>
          `${invitedClient?.User?.FirstName}${
            invitedClient?.User?.LastName ? ` ${invitedClient?.User?.LastName}` : ''
          }`,
      )
      ?.join(', ');
  } else if (uninvitedClientsLength) {
    totalClients = uninvitedClientsLength;
    clients = uninvitedClients?.join(', ');
  }

  return { totalClients, clients };
};

export const extractClientNames = (
  transaction: PropertyTransaction,
  excludeInvitedBuyers: boolean = false,
) => {
  const invitedClients =
    transaction?.Participants?.filter(
      (p) =>
        p.Role.Title === ClientCategory[ClientCategory.Seller] ||
        (!excludeInvitedBuyers && p.Role.Title === ClientCategory[ClientCategory.Buyer]),
    ).map((client) => `${client.FirstName} ${client.LastName}`) || [];

  const uninvitedClients = transaction?.TransactionClients || [];

  return [...invitedClients, ...uninvitedClients];
};
