import styles from './styles.module.scss';
import { FC, useState } from 'react';
import { map } from 'lodash-es';
import { IconsVariant } from '../types';
import LocationPrefsListItem from '../LocationPrefsListItem';
import { SchoolsModal } from 'pages/OnBoardingWizard/components/StageParts/StageNeighbors/Options/SchoolsModal';
import { useDispatch, useSelector } from 'react-redux';
import { appOpenModalEffect, onBoardingWizardEffect } from 'store/effects';
import CommutesModal from 'pages/OnBoardingWizard/components/StageParts/StagePrefs/CommutesModal';
import { PREF_TYPE, convertPrefs } from 'settings/constants/preferences';
import { IMPORTANCE, NEIGHBORHOOD_TYPE, getDefaultValues } from '../../constants';
import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { addSearchInstance } from 'store/effects/clientInstances';
import { Button } from 'components';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { PENDING } from 'settings/constants/apiState';
import ArrowRightFull from 'components/Icons/ArrowRightFull';

interface ILocationPrefsList {
  onNext: () => void;
}

const LocationPrefsList: FC<ILocationPrefsList> = ({ onNext }) => {
  const dispatch = useDispatch();
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const {
    NeighborhoodPrefs: neighborhoodPrefsDefault,
    CommutePrefs: commutePrefsDefault,
    Schools: schoolPrefsDefault,
  } = defaultClientSearch || {};
  const { onBoarding } = useSelector(getOnBoardingData);
  const isPending = onBoarding.state === PENDING;

  const [schools, setSchools] = useState(schoolPrefsDefault ?? {});
  const [commutes, setCommutes] = useState(
    commutePrefsDefault
      ? commutePrefsDefault?.filter(({ IsImportantLocation }) => !IsImportantLocation)
      : [],
  );
  const [importantLocations, setImportantLocations] = useState(
    commutePrefsDefault
      ? commutePrefsDefault?.filter(({ IsImportantLocation }) => IsImportantLocation)
      : [],
  );
  const [editImportantLocations, setEditImportantLocations] = useState(false);
  const [neighborhood, setNeighborhood] = useState<string[]>(
    getDefaultValues(neighborhoodPrefsDefault, NEIGHBORHOOD_TYPE),
  );

  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  function active(id: string) {
    if (id === 'COMMUTE') {
      return commutes.length > 0;
    } else if (id === 'PROXIMITY') {
      return importantLocations.length > 0;
    } else if (id === 'School') return Object.keys(schools).length > 0;
    return neighborhood.includes(id);
  }

  function onClick(id: string) {
    if (id === 'COMMUTE') {
      setEditImportantLocations(false);
      openModal(CommutesModal.id);
      return;
    } else if (id === 'PROXIMITY') {
      setEditImportantLocations(true);
      openModal(CommutesModal.id);
      return;
    } else if (id === 'School') {
      openModal((SchoolsModal as any).id);
      return;
    }

    const newValue = [...neighborhood];
    const index = newValue.findIndex((val) => val === id);
    if (index === -1) {
      newValue.push(id);
    } else {
      newValue.splice(index, 1);
    }
    setNeighborhood(newValue);
  }

  const onSave = () => {
    const commuteLocations = [...commutes, ...importantLocations];

    const convertedNeighborhoodPrefs = convertPrefs(
      neighborhood,
      IMPORTANCE.someWhat,
      'Preference',
      neighborhoodPrefsDefault,
    );

    const cfg = {
      stageIndex: 'homePrefs',
      DefaultPropertySearchPreferences: {
        ...defaultClientSearch,
        [PREF_TYPE.neighborhoodPrefs]: convertedNeighborhoodPrefs?.length
          ? convertedNeighborhoodPrefs
          : undefined,
        [PREF_TYPE.commutePrefs]: commuteLocations?.length > 0 ? commuteLocations : undefined,
        [PREF_TYPE.school]: schools ?? undefined,
      },
    };
    dispatch(
      onBoardingWizardEffect(cfg, {}, (err) => {
        if (!err) {
          dispatch(addSearchInstance({ data: cfg.DefaultPropertySearchPreferences }));
          onNext();
        }
      }),
    );
  };

  const onSaveCommuteLocationsValues = (values, isImportantLocation) => {
    const locations = values?.map((value) => ({
      ...value,
      IsImportantLocation: isImportantLocation,
    }));
    isImportantLocation ? setImportantLocations(locations) : setCommutes(locations);
  };

  const LocationPrefItems = [
    {
      label: 'Commute Time',
      variant: IconsVariant.COMMUTE_Time,
      color: 'darkBlue',
      id: 'COMMUTE',
      subtitle: commutes?.map(({ Name }) => Name)?.join(', '),
    },
    {
      label: 'Proximity to Important Locations',
      variant: IconsVariant.PROXIMITY_LOCATION,
      color: 'red',
      id: 'PROXIMITY',
      subtitle: importantLocations?.map(({ Name }) => Name)?.join(', '),
    },
    {
      label: 'Schools',
      variant: IconsVariant.SCHOOLS,
      color: 'yellow',
      id: 'School',
    },
    {
      label: 'Walkability',
      variant: IconsVariant.WALK,
      color: 'purple',
      id: 'Walkability',
    },
    {
      label: 'Public Transit',
      variant: IconsVariant.PUBLIC_TRANSIT,
      color: 'blue',
      id: 'PublicTransit',
    },
    {
      label: 'Arts & Entertainment',
      variant: IconsVariant.ARTS,
      color: 'darkBlue',
      id: 'ArtAndEntertainment',
    },
    {
      label: 'Parks & Nature',
      variant: IconsVariant.PARK_NATURE,
      color: 'red',
      id: 'ParksAndNature',
    },
    {
      label: 'Fitness',
      variant: IconsVariant.FITNESS,
      color: 'yellow',
      id: 'Fitness',
    },
    {
      label: 'Coffee Shops',
      variant: IconsVariant.COFFEE_SHOPS,
      color: 'purple',
      id: 'CoffeeShops',
    },
    {
      label: 'Restaurants',
      variant: IconsVariant.RESTAURANT,
      color: 'blue',
      id: 'RestaurantsBars',
    },
    {
      label: 'Nightlife',
      variant: IconsVariant.NIGHT_LIFE,
      color: 'darkBlue',
      id: 'Nightlife',
    },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        {map(LocationPrefItems, ({ label, variant, color, id, subtitle }, index) => (
          <LocationPrefsListItem
            active={active(id)}
            label={label}
            variant={variant}
            color={color}
            key={index}
            onClick={() => {
              onClick(id);
            }}
            subtitle={subtitle || ''}
          />
        ))}

        <SchoolsModal
          onNext={(value) => {
            setSchools(value);
          }}
          value={schools}
        />
        <CommutesModal
          onNext={(val) => onSaveCommuteLocationsValues(val, editImportantLocations)}
          value={editImportantLocations ? importantLocations : commutes}
          title={editImportantLocations ? 'Add Important Location' : 'Add Commute'}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          titleClassName={styles.titleClassName}
          className={styles.button}
          title="Continue"
          onClick={onSave}
          icon={<ArrowRightFull color={'#fff'} />}
          isPending={isPending}
        />
      </div>
    </>
  );
};

export default LocationPrefsList;
