import { simpleHash } from 'helpers';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CHAT_ADVICE_STATUS,
  EXTERNAL_TOKEN,
  DEVICE_TOKEN,
  TOKEN_CONTEXT,
  SEARCH_CRITERIA,
  MOBILE_REDIRECTION_URL,
  CONTEXT_PROPERTY_FEED,
  BROKERAGE_LOGO,
} from 'settings/constants/localStorage';

export class LocalStorage {
  static getTokens() {
    return {
      accessToken: localStorage.getItem(ACCESS_TOKEN),
      refreshToken: localStorage.getItem(REFRESH_TOKEN),
    };
  }

  static getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  static setTokens({ accessToken, refreshToken }) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  //Clear everything except for Device Token and External Token
  static clearLocalStorage() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(CHAT_ADVICE_STATUS);
    localStorage.removeItem(TOKEN_CONTEXT);
    localStorage.removeItem(SEARCH_CRITERIA);
    localStorage.removeItem(CONTEXT_PROPERTY_FEED);
    localStorage.removeItem(BROKERAGE_LOGO);
  }

  static getChatAdviceStatus() {
    return localStorage.getItem(CHAT_ADVICE_STATUS);
  }

  static setChatAdviceStatus(value) {
    localStorage.setItem(CHAT_ADVICE_STATUS, value);
  }

  static getExternalToken() {
    return localStorage.getItem(EXTERNAL_TOKEN);
  }

  static setExternalToken(value) {
    localStorage.setItem(EXTERNAL_TOKEN, value);
  }

  static getDeviceToken() {
    return localStorage.getItem(DEVICE_TOKEN);
  }

  static setDeviceToken(token) {
    localStorage.setItem(DEVICE_TOKEN, token);
  }

  static removeDeviceToken() {
    localStorage.removeItem(DEVICE_TOKEN);
  }

  /*
   * TokenContext is used in conjunction with SessionStorage LoginContext
   * to confirm that the currently logged in user is the same one for
   * the LocalStorage persisted Token
   */
  static getTokenContext() {
    return localStorage.getItem(TOKEN_CONTEXT);
  }

  static setTokenContextFromEmail(email) {
    const tokenContext = simpleHash(email);
    return localStorage.setItem(TOKEN_CONTEXT, tokenContext);
  }

  static getSearchCriteria() {
    return localStorage.getItem(SEARCH_CRITERIA);
  }

  static setSearchCriteria(value) {
    localStorage.setItem(SEARCH_CRITERIA, JSON.stringify(value));
  }

  static getContextPropertyFeed() {
    return localStorage.getItem(CONTEXT_PROPERTY_FEED);
  }

  static setContextPropertyFeed(value) {
    localStorage.setItem(CONTEXT_PROPERTY_FEED, JSON.stringify(value));
  }

  static getMobileRedirectionUrl() {
    return localStorage.getItem(MOBILE_REDIRECTION_URL);
  }

  static setBrokerageLogo(value) {
    localStorage.setItem(BROKERAGE_LOGO, value);
  }

  static getBrokerageLogo(value) {
    return localStorage.getItem(BROKERAGE_LOGO, value);
  }
}
