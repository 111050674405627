import { Col, Row } from 'components-antd';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFavoritesPropertiesV3Effect } from 'store/effects/feedv3';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getFeedSortSelector, getSubFilterTypeSelector } from 'store/selectors/feed';
import {
  getFavoritesPropertiesList,
  getFavoritesPropertiesMarkersSelector,
  getFeedCriteriaSelectorV3,
  getSavedSearchesPropertiesList,
  getSavedSearchesPropertiesMarkersSelector,
} from 'store/selectors/feedv3';
import ClientProperties from './components/ClientProperties';
import Map from '../common/Map';
import { Spinner } from 'components';

import { EDIT } from 'settings/constants/mode';
import {
  selectedSearchAction,
  setCriteriaAction,
  setCriteriaModeAction,
} from 'store/actions/feedv3';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import ClientSearch from '../ClientSearch';
import { FilterDrawer } from '../Feed/Drawers';
import { ClientHeader } from './components/ClientHeader';
import { ViewSwitcher } from './ViewSwitcher/ViewSwitcher';
import { useSearchInstanceV3Effect } from './hooks/useSearchInstanceV3Effect';
import styles from './styles.module.scss';
import { getSearchInstancesFormattedSelector } from 'store/selectors/mySearches';
import { getUserRolesMapSelector } from 'store/selectors/user';
import {
  getSearchInstancesEffect,
  sortFeedEffect,
  updateSearchInstanceEffect,
} from 'store/effects';
import { SEARCH_INSTANCE_STATUS } from 'app-constants';
import classNames from 'classnames';
import { setSubFilterTypeAction, toggleFeedMapDrawingModeAction } from 'store/actions/feed';
import { SORT_DESC } from 'settings/constants/sort';
import { MapDrawControlsSearchWrapper } from '../SearchResults/components/MapDrawControlsSearchWrapper';
import { LocationType } from 'types';
import { toggleSearchMapDrawingModeAction } from 'store/actions/searchResults';

const ClientFeed = () => {
  const dispatch = useDispatch();
  const sort = useSelector(getFeedSortSelector);
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const { selectedSearch } = useSelector(getFeedMetaSelectorV3);
  const searchContext = useSelector(getCurrentContextSelector);
  const getSearchInstanceV3 = useSearchInstanceV3Effect();
  const [showSavedSearches, setShowSavedSearches] = useState<boolean>(false);
  const [isFavortiesSectionVisible, setIsFavortiesSectionVisible] = useState(false);
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const polygonLocations =
    criteria?.Locations?.filter(({ Type }) => Type === LocationType.Polygon) ?? [];
  const [viewType, setViewType] = useState('Split View');
  const {
    properties,
    isPending: savedPropertiesLoading,
    totalCount,
  } = useSelector(getSavedSearchesPropertiesList);
  const {
    properties: favProperties,
    isPending: favPropertiesLoading,
    totalCount: favTotalCount,
  } = useSelector(getFavoritesPropertiesList);
  const propertiesMarkers = useSelector(getSavedSearchesPropertiesMarkersSelector);
  const favPropertiesMarkers = useSelector(getFavoritesPropertiesMarkersSelector);
  const {
    isIdle,
    isData,
    data: savedSearches,
    loaded,
  } = useSelector(getSearchInstancesFormattedSelector);

  const { isClient } = useSelector(getUserRolesMapSelector);

  const disableDraw = subFilterType === 'Recommended';

  const noActiveSavedSearches = useMemo(() => {
    return (
      savedSearches?.filter((item) => item?.status === SEARCH_INSTANCE_STATUS.ACTIVE)?.length < 1
    );
  }, [savedSearches]);

  useEffect(() => {
    if (isFavortiesSectionVisible) fetchFavoriteProperties();
  }, [isFavortiesSectionVisible]);

  useEffect(() => {
    if (selectedSearch && !isOpenSearchModal) {
      !isFavortiesSectionVisible ? getSearchInstanceV3(selectedSearch) : fetchFavoriteProperties();
    }
  }, [subFilterType, sort, searchContext?.ContextKey, selectedSearch]);

  useEffect(() => {
    dispatch(setCriteriaModeAction({ mode: EDIT }));
    dispatch(sortFeedEffect({ order: SORT_DESC }));
  }, []);

  const fetchFavoriteProperties = () => {
    dispatch(
      getFavoritesPropertiesV3Effect({
        queryParams: {
          sortField: sort?.fields?.[0],
          sortOrder: sort?.order,
          contextId: searchContext?.ContextKey,
        },
      }),
    );
  };

  let propertyProps = {
    isPending: isFavortiesSectionVisible ? favPropertiesLoading : savedPropertiesLoading,
    properties: isFavortiesSectionVisible ? favProperties : properties,
  };

  const [isOpenSearchModal, setIsOpenSearchModal] = useState<boolean>(false);
  const cfg = { contextKey: searchContext?.ContextKey };

  useEffect(() => {
    if (isClient && searchContext && !selectedSearch && isIdle) {
      dispatch(
        getSearchInstancesEffect(cfg, {}, (err, result) => {
          if (!err && !selectedSearch) {
            const savedSearches = result?.data?.result?.filter(
              (item) => item?.Status === SEARCH_INSTANCE_STATUS.ACTIVE,
            );
            if (savedSearches?.length > 0) {
              const data = savedSearches?.[0];
              const item = {
                id: data?.Id,
                name: data?.Name,
                criterias: data?.DefaultPropertySearchPreferences,
              };
              dispatch(selectedSearchAction({ selectedSearch: item }));
            }
          }
        }),
      );
    }
  }, [isClient, searchContext, selectedSearch, isIdle]);
  const [showBlurBg, setShowBlurBg] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  useEffect(() => {
    if (isFirstRender && loaded) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, loaded]);

  const getViewType = (value) => {
    setViewType(value);
  };

  const editModeCallback = (err) => {
    if (!err) {
      dispatch(
        getSearchInstancesEffect(cfg, {}, (err, result) => {
          if (!err) {
            const searchActive = result?.data?.result?.find(
              (item) => item?.Id === selectedSearch?.id,
            );
            const item = {
              id: searchActive?.Id,
              name: searchActive?.Name,
              criterias: searchActive?.DefaultPropertySearchPreferences,
            };
            dispatch(selectedSearchAction({ selectedSearch: item }));
          }
        }),
      );
    }
  };

  const setDrawnPolygonDispatchFn = (polygon) => {
    const newCriteria = {
      ...criteria,
      Locations: [polygon],
      id: selectedSearch?.id,
    };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    dispatch(updateSearchInstanceEffect(newCriteria, {}, (err) => editModeCallback(err)));
    dispatch(toggleSearchMapDrawingModeAction(false));
  };

  const polygonsUpdateFn = (polygons) => {
    const nonPolygonLocations =
      criteria?.Locations?.filter(({ Type }) => Type !== LocationType.Polygon) ?? [];
    const newCriteria = {
      ...criteria,
      Locations: [...nonPolygonLocations, ...polygons],
      id: selectedSearch?.id,
    };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    dispatch(updateSearchInstanceEffect(newCriteria, {}, (err) => editModeCallback(err)));
    dispatch(toggleSearchMapDrawingModeAction(false));
  };

  const setPolygonLocations = (payload) => {
    const nonPolygonLocations =
      criteria?.Locations?.filter(({ Type }) => Type !== LocationType.Polygon) ?? [];
    const newCriteria = { ...criteria, Locations: [...nonPolygonLocations, ...payload] };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    getSearchInstanceV3({ criterias: newCriteria });
  };

  let mapProps = {
    disableDraw,
    markers: isFavortiesSectionVisible ? favPropertiesMarkers : propertiesMarkers,
    properties: isFavortiesSectionVisible ? favProperties : properties,
    totalCount: isFavortiesSectionVisible ? favTotalCount : totalCount,
    setDrawnPolygonDispatchFn: setDrawnPolygonDispatchFn,
    polygonLocations: polygonLocations,
  };

  return (
    <div className={styles.clientFeedWrapper}>
      {!isFirstRender ? (
        <>
          {loaded && noActiveSavedSearches ? (
            <ClientSearch
              onClose={() => setIsOpenSearchModal(false)}
              hideCloseButton={noActiveSavedSearches}
              showSavedSearches={showSavedSearches}
              setShowSavedSearches={setShowSavedSearches}
            />
          ) : (
            <>
              <ClientHeader
                isFavortiesSectionVisible={isFavortiesSectionVisible}
                setIsFavortiesSectionVisible={setIsFavortiesSectionVisible}
                setIsOpenSearchModal={setIsOpenSearchModal}
                setShowSavedSearches={setShowSavedSearches}
                setShowBlurBg={(value) => setShowBlurBg(value)}
                isPending={propertyProps?.isPending}
              />
              <Row className={styles.clientFeed}>
                {showBlurBg && <div className={styles.backdropBlur} />}
                {viewType !== 'List Only' && (
                  <Col
                    md={viewType === 'Split View' ? 11 : 24}
                    sm={24}
                    xs={24}
                    className={styles.mapContainer}
                  >
                    <Map {...mapProps} />
                  </Col>
                )}
                {viewType !== 'Map Only' && (
                  <Col
                    md={viewType === 'Split View' ? 13 : 24}
                    sm={24}
                    xs={24}
                    className={styles.propertiesContainer}
                  >
                    <ClientProperties
                      {...propertyProps}
                      viewType={viewType}
                      searchInstanceId={selectedSearch?.id}
                      isFavorites={isFavortiesSectionVisible}
                      isFavortiesSectionVisible={isFavortiesSectionVisible}
                    />
                    <FilterDrawer />
                  </Col>
                )}
              </Row>
              <div className={styles.mapSwitchers}>
                <ViewSwitcher getView={getViewType} />
                {viewType !== 'List Only' && !disableDraw ? (
                  <MapDrawControlsSearchWrapper
                    polygonLocations={polygonLocations}
                    setPolygonLocations={setPolygonLocations}
                    polygonsUpdateFn={polygonsUpdateFn}
                  />
                ) : null}
              </div>
              {isOpenSearchModal && (
                <ClientSearch
                  onClose={() => setIsOpenSearchModal(false)}
                  showSavedSearches={showSavedSearches}
                  setShowSavedSearches={setShowSavedSearches}
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div className={styles.spinner}>
            <Spinner loaderClassName={classNames(styles.loader)} />
          </div>
        </>
      )}
    </div>
  );
};

export default ClientFeed;
