import React, { useState } from 'react';
import { Modal, ModalProps } from 'components-antd';
import { useDispatch, useSelector } from 'react-redux';
import { createPartnerByAgentEffect } from 'store/effects';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { getLoadingRecommendedPartnersByAgent } from 'store/selectors/partners';
import {
  requestGetPartnersListByCategoryIdEffect,
  requestGetRecommendedPartnersByAgentListEffect,
} from 'store/effects/partners';
import { AddVendorForm, OnError, OnSuccess } from './components';

import styles from './styles.module.scss';

interface Props extends ModalProps {
  handleClose: () => void;
}

export const AddVendorModal: React.FC<Props> = ({ open, handleClose, ...rest }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { areasOfOperation, category: categoryId } = useSelector(getConciergeSearchSelector);
  const { isPending } = useSelector(getLoadingRecommendedPartnersByAgent);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState<string>('');
  const submitVendor = (values: any) => {
    setIsLoading(true);
    dispatch(
      createPartnerByAgentEffect(
        {
          ...values,
          Email: values.Email?.toLowerCase(),
          AreasOfOperation: areasOfOperation,
          SuiteUnit: '',
        },
        (err) => {
          if (!err) {
            if (!isPending) dispatch(requestGetRecommendedPartnersByAgentListEffect());
            dispatch(requestGetPartnersListByCategoryIdEffect());
            setIsLoading(false);
            setBusinessName(values.BusinessName);
            setIsSuccess(true);
            return;
          }
          setBusinessName(values.BusinessName);
          setIsLoading(false);
          setIsError(true);
        },
      ),
    );
  };
  return (
    <Modal
      className={styles.common}
      footer={null}
      width={675}
      open={open}
      onCancel={handleClose}
      destroyOnClose
      closable={!(isSuccess || isError)}
      {...rest}
    >
      {!(isSuccess || isError) && (
        <AddVendorForm handleFormSubmit={submitVendor} isLoading={isLoading} />
      )}
      {isSuccess && <OnSuccess handleClose={handleClose} name={businessName} />}
      {isError && <OnError handleClose={handleClose} name={businessName} />}
    </Modal>
  );
};
