import { UploadFile } from 'antd';
import { showErrorMessage } from 'helpers';
import React from 'react';
import { apiServer } from 'settings/web-services/api';
import { PDFDocument } from 'pdf-lib';

export const formHeadings = {
  0: 'New Form',
  1: 'Upload a File',
  2: 'My Library',
  3: 'Recent Uploads',
  4: 'Create a Template',
};

export const templateCategories = [
  {
    label: 'Brokerage',
    value: 'Brokerage',
  },
  {
    label: 'CAR',
    value: 'CAR',
  },
  {
    label: 'Internal',
    value: 'Internal',
  },
  {
    label: 'Lenders',
    value: 'Lenders',
  },
];

export const initialUploadErrorState = {
  templateNameError: '',
  categoriesError: '',
};

export const initialFieldState = {
  templateName: '',
  categories: [],
  saveAsTemplate: false,
  publishToTeam: false,
};

export const prepareFileFormData = (files, existingFormData?) => {
  const formData = existingFormData || new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', (files[i] as any).originFileObj);
  }

  return formData;
};

export const handleFileUpload = async ({
  fileName,
  files,
  keys,
}: {
  fileName?: string;
  files: UploadFile<any>[];
  keys?: React.Key[];
}) => {
  try {
    let formData = new FormData();
    formData.append('templateName', fileName || '');
    keys && formData.append('templateIds', JSON.stringify(keys));

    formData = prepareFileFormData(files, formData);

    const response = await apiServer.post('/fillDocument/formTemplate', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const formTemplate = response?.data?.value?.formTemplate;

    if (formTemplate?.TemplateId) {
      return { templateId: formTemplate.TemplateId, name: fileName };
    } else if (formTemplate?.DocumentLink) {
      return { documentLink: formTemplate.DocumentLink, name: fileName };
    } else {
      throw new Error('Invalid form selected response');
    }
  } catch (error) {
    showErrorMessage(error);
  }
};

export const smartFormPublishedByOption = {
  Name: 'Mosaik',
  value: 'Mosaik',
  Email: '',
  TagId: -1,
  Id: -1,
};

export const getStagesStep = (dynamicForm) => ({
  FormCategories: 0,
  UploadFile: 1,
  Library: 2,
  ...(!dynamicForm && { RecentUploads: 3, NewTemplate: 4 }),
});

export const isEditDynamicForm = (stages) => !stages.RecentUploads && !stages.NewTemplate;

export const getMergedDetails = (oldDetails, newDetails) => {
  let details = [...oldDetails];

  const oldPublicIds = oldDetails.map(({ PublicId }) => PublicId);

  const filteredNewDetails = newDetails.filter(({ PublicId }) => !oldPublicIds.includes(PublicId));

  details = [...details, ...filteredNewDetails];

  return details;
};
