import { daysFromToday } from 'helpers';
import { propertyStatusOptions } from 'settings/constants/properties';

const checkRange = (value: number, min: number, max: number) => {
  return isValidNumber(min) || isValidNumber(max)
    ? isValidNumber(value)
      ? isValidNumber(min) && isValidNumber(max)
        ? value >= min && value <= max
        : isValidNumber(min)
        ? value >= min
        : isValidNumber(max)
        ? value <= max
        : false
      : false
    : true;
};

function isValidNumber(value) {
  return value === '' ? false : !isNaN(Number(value));
}

export const filterProperties = (properties: any, filter: any) => {
  if (!filter) return properties;
  const filteredProperties = properties?.filter((property) => {
    const filterCheck: boolean[] = [];
    const noHOA = filter?.NoHOA;

    // Check ActivityStatus
    const statusLength = filter?.Status?.length;
    filterCheck.push(
      statusLength
        ? filter?.Status?.includes(
            propertyStatusOptions?.find((status) => status?.name === property?.ActivityStatus)
              ?.value,
          )
        : true,
    );

    // Check SellingPrice
    const price = property?.SellingPrice;
    const minPrice = filter.PriceRange?.Min;
    const maxPrice = filter.PriceRange?.Max;
    filterCheck.push(checkRange(price, minPrice, maxPrice));

    // Check HOA
    const hoa = property?.AssociationInfo?.MonthlyFee;
    const minHoa = filter.HOARange?.Min;
    const maxHoa = filter.HOARange?.Max;
    filterCheck.push(noHOA ? true : checkRange(hoa, minHoa, maxHoa));

    // Check NumBeds
    const beds = property?.NumBeds;
    const minBeds = filter?.NumBedroomsRange?.Min;
    const maxBeds = filter?.NumBedroomsRange?.Max;
    filterCheck.push(checkRange(beds, minBeds, maxBeds));

    // Check NumBathsTotal
    const baths = property?.NumBathsTotal;
    const minBaths = filter?.NumBathroomsRange?.Min;
    const maxBaths = filter?.NumBathroomsRange?.Max;
    filterCheck.push(checkRange(baths, minBaths, maxBaths));

    // Check SquareFeet
    const squareFeet = property?.SquareFeet;
    const minSquareFeet = filter?.SquareFeetRange?.Min;
    const maxSquareFeet = filter?.SquareFeetRange?.Max;
    filterCheck.push(checkRange(squareFeet, minSquareFeet, maxSquareFeet));

    // Check LotSize
    const lotSize = property?.LotSizeSquareFeet;
    const minLotSize = filter?.LotSizeRange?.Min;
    const maxLotSize = filter?.LotSizeRange?.Max;
    filterCheck.push(checkRange(lotSize, minLotSize, maxLotSize));

    // Check YearBuilt
    const yearBuild = property?.YearBuilt;
    const minYearBuild = filter?.YearBuiltRange?.Min;
    const maxYearBuild = filter?.YearBuiltRange?.Max;
    filterCheck.push(checkRange(yearBuild, minYearBuild, maxYearBuild));

    // Check NumOfStories
    const stories = property?.NumOfStories;
    const minStories = filter?.StoriesRange?.Min;
    const maxStories = filter?.StoriesRange?.Max;
    filterCheck.push(checkRange(stories, minStories, maxStories));

    // Check DaysOnMarket
    const daysOnMarket = property?.ListingDate ? daysFromToday(property?.ListingDate) : null;
    filterCheck.push(
      filter?.MaxDaysOnMarket && daysOnMarket ? daysOnMarket <= filter?.MaxDaysOnMarket : true,
    );

    // Check NumOfGarageSpaces
    const parkingSpaces = property?.Parking?.NumOfGarageSpaces;
    const minParkingSpaces = filter?.ParkingSpacesRange?.Min;
    const maxParkingSpaces = filter?.ParkingSpacesRange?.Max;
    filterCheck.push(checkRange(parkingSpaces, minParkingSpaces, maxParkingSpaces));

    // Check HomeType
    const homeTypeLength = filter?.HomeType?.length;
    filterCheck.push(homeTypeLength ? filter?.HomeType?.includes(property?.HomeType) : true);
    // Check HOA

    filterCheck.push(
      noHOA
        ? !property.AssociationInfo.IsAssociation || property?.AssociationInfo?.MonthlyFee === 0
        : true,
    );

    return filterCheck.every((filter) => filter);
  });

  return filteredProperties;
};
