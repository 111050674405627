import React, { Dispatch, Fragment, SetStateAction, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Avatar, Button, Checkbox, SimpleModal } from 'components';
import { Close, GreenCheckMark, Messages } from 'components/Icons';
import {
  convertNameToAvatarPlaceholder,
  getIfExistThread,
  showErrorMessage,
  showSuccessMessage,
} from 'helpers';
import { Row, Col, Button as AntButton } from 'components-antd';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';
import { getAgentTypeSelector, getUserId } from 'store/selectors/user';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { AGENT_TYPE, DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { TEAM_BASIC, THIRD_PARTY } from 'settings/constants/roles';
import RecommendedIcon from '../IconRecommended';
import UnRecommendedIcon from '../IconUnRecommended';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { getPartnerBusinessNameAndId } from 'store/selectors/singlePartner';
import { getThreadsListSelector } from 'store/selectors/sockets/threads';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { useParams } from 'react-router-dom';
import { cloneDeep, get } from 'lodash-es';
import { setMultipleRequestQuotesEffect } from 'store/effects/quotes';
import { MessageIcon } from '../../../../icons';
import { InfoIcon } from './icons';
import { ResendIcon } from './icons/ResendIcon';
import { sendInviteEffect } from 'store/effects';

import styles from './styles.module.scss';
import { RecommendationModal } from '../RecommendationModal';
import {
  requestChangeRecommendPartnerStatus,
  requestGetRecommendedPartnersByAgentListEffect,
} from 'store/effects/partners';
import { isLoadingPartnerRecommendation } from 'store/selectors/partners';

type Props = {
  partner: any;
  setSelectedPartnerId: Dispatch<SetStateAction<string>>;
  setViewPartnerDetails: Dispatch<SetStateAction<boolean>>;
};

export const PartnerCard: React.FC<Props> = ({
  partner,
  setViewPartnerDetails,
  setSelectedPartnerId,
}) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const { id } = useSelector(getPartnerBusinessNameAndId);
  const userId = useSelector(getUserId);
  const threads = useSelector(getThreadsListSelector);
  const isLoadingRecommendation = useSelector(isLoadingPartnerRecommendation);

  const { categoryId: partnerCategoryId } = useParams<any>();

  const isMemberAgent = () => agentType === AGENT_TYPE.Team && agentRole === TEAM_BASIC;
  const isInvitePending = useMemo(
    () => !get(partner, 'ThirdParty.User.IsEmailVerified'),
    [partner],
  );
  const isCompletedProfile = useMemo(
    () => get(partner, 'ThirdParty.User.CompletedProfile'),
    [partner],
  );

  const initiateSendingMessageToPartner = useCallback(
    (partner) => {
      const thread = getIfExistThread(
        threads,
        [id, userId],
        ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT,
      );

      dispatch(openMessagesDrawerAction(true));

      if (!thread) {
        const firstName = partner?.ThirdParty?.User?.FirstName;
        const lastName = partner?.ThirdParty?.User?.LastName;

        dispatch(
          changeMessagesDrawerTypeAction({
            type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
            params: {
              threadId: thread?.Id || null,
              participants: [
                {
                  id: partner?.Id,
                  name: `${firstName} ${lastName || ''} (${partner?.BusinessName || ''})`,
                  value: partner?.Id,
                  role: THIRD_PARTY,
                  avatarUrl: partner?.LogoUrl,
                  firstName: partner?.FirstName,
                  lastName: partner?.LastName,
                },
              ],
            },
          }),
        );
      } else {
        dispatch(
          changeMessagesDrawerTypeAction({
            type: DRAWER_MESSAGES_TYPES.CHAT,
            params: {
              threadId: thread.Id,
            },
          }),
        );
      }
    },
    [dispatch, threads, id, userId],
  );

  const handleMessageButtonClick = useEmailVerificationWall(
    ActionToInterrupt.MESSAGE_TO_PARTNER,
    initiateSendingMessageToPartner,
    {
      categoryId: partnerCategoryId,
    },
  );

  const onSelectPartner = (partner, checked) => {
    const newPartners = cloneDeep(multiple?.partners) || [];
    if (checked) {
      newPartners.push(partner);
    } else {
      const removedPartnerIndex = newPartners.findIndex((part) => part?.Id === partner?.Id);

      if (removedPartnerIndex !== -1) {
        newPartners.splice(removedPartnerIndex, 1);
      }
    }
    dispatch(setMultipleRequestQuotesEffect({ partners: newPartners }));
  };

  const getIsPartnerChecked = (partner) =>
    (multiple?.partners || []).some((part) => part?.Id === partner?.Id);

  const getMultipleActions = (partner) =>
    partner?.isMyRecommended ? (
      <div className={styles.multipleActions}>
        <div className={styles.multipleRecommendedFlag}>
          <RecommendedIcon width={24} height={24} />
        </div>
        <Checkbox
          labelClassName={styles.checkboxLabelWrapper}
          onChange={(event, val, checked) => {
            event.stopPropagation();
            onSelectPartner(partner, checked);
          }}
          checked={getIsPartnerChecked(partner)}
          testid="check_partner"
        />
      </div>
    ) : (
      <Checkbox
        onChange={(event, val, checked) => {
          event.stopPropagation();
          onSelectPartner(partner, checked);
        }}
        checked={getIsPartnerChecked(partner)}
      />
    );
  const [sendingInvite, setSendingInvite] = useState<boolean>(false);
  const sendPartnerInvite = useCallback(
    (id) => {
      if (sendingInvite) return;
      const effect = sendInviteEffect;
      setSendingInvite(true);

      dispatch(
        effect({ id: partner.Id }, {}, (err) => {
          setSendingInvite(false);
          if (!err) {
            showSuccessMessage(
              'The email containing the link to complete profile has been sent successfully.',
            );
          } else {
            showErrorMessage(err);
          }
        }),
      );
    },
    [sendingInvite, partner.Id, dispatch],
  );

  const onChangeRecommendation = useCallback(() => {
    dispatch(
      requestChangeRecommendPartnerStatus(
        {
          recommend: partner?.isMyRecommended,
          partnerId: partner?.Id,
        },
        (err) => {
          if (!err) {
            dispatch(requestGetRecommendedPartnersByAgentListEffect());
          }
          setIsModalOpen(false);
        },
      ),
    );
  }, [partner, dispatch]);
  return (
    <>
      <Col xs={24} sm={24} md={24} key={partner?.Id}>
        <div
          testid="partner_item"
          className={classNames(styles.partner, {
            [styles.checked]: getIsPartnerChecked(partner),
          })}
        >
          <div
            className={styles.partnerDetails}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedPartnerId(partner?.Id);
              setViewPartnerDetails(true);
            }}
          >
            <Avatar
              src={partner?.LogoUrl}
              placeholder={
                <div className={styles.avatarPlaceholder}>
                  {convertNameToAvatarPlaceholder(partner?.BusinessName)}
                </div>
              }
              avatarClassName={styles.avatar}
              className={styles.avatarWrapper}
            />
            <div className={styles.partnerInfo}>
              <div testid="business_name" className={styles.partnerInfoTitle}>
                {partner?.BusinessName || 'Unknown name'}
              </div>
              {partner?.AreasOfOperation?.length > 0 ? (
                <div testid="description" className={styles.partnerInfoDesc}>
                  <div className={styles.title}>
                    <GreenCheckMark width={16} height={16} /> Serves:
                  </div>
                  <div className={styles.itemsWrapper}>
                    {partner.AreasOfOperation.map((area, index) => (
                      <div key={`${index}--partner-card-area`}>
                        {area?.City}, {area?.State}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              <div testid="description" className={styles.partnerInfoDesc}>
                <div className={styles.title}>
                  <GreenCheckMark width={16} height={16} /> Offers:
                </div>
                <div className={classNames(styles.itemsWrapper, styles.servicesOffered)}>
                  {partner?.PartnerTags?.map(({ Name }) => Name).join(', ') || 'General Services'}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.partnerButtons}>
            {!isCompletedProfile ? (
              <div className={styles.invitePendingWrapper}>
                <div className={styles.status}>
                  <div>
                    <InfoIcon />
                  </div>
                  <div>Not Activated</div>
                </div>
                <div className={styles.resend} onClick={sendPartnerInvite}>
                  <ResendIcon />
                </div>
              </div>
            ) : !multiple?.modeEnable ? (
              <>
                <Button
                  data-recommended={partner?.isMyRecommended}
                  className={styles.recommended}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsModalOpen(true);
                  }}
                  iconLeft={
                    partner?.isMyRecommended ? (
                      <RecommendedIcon width={24} height={24} />
                    ) : (
                      <UnRecommendedIcon
                        width={24}
                        height={24}
                        style={{ color: '#747475', opacity: 0.75 }}
                      />
                    )
                  }
                  disabled={isMemberAgent()}
                  testid="recommend_button"
                />

                <Button
                  className={styles.messages}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMessageButtonClick(partner);
                  }}
                  iconLeft={<MessageIcon />}
                  testid="message_button"
                />
              </>
            ) : (
              getMultipleActions(partner)
            )}
          </div>
        </div>
      </Col>
      <RecommendationModal
        selectedPartner={partner}
        isOpen={isModalOpen}
        isLoadingRecommendation={isLoadingRecommendation}
        onChangeRecommendation={onChangeRecommendation}
        onCloseModal={() => setIsModalOpen(false)}
      />
    </>
  );
};
